
export const globals: any = {
  env : 'testbed',    // can be local, testbed or prod

  favIcon : 'https://examdesk.co/skin/favicon.png',
  client_name : 'Examdesk',
  client_logo : 'https://onlinetest.proctur.com/skin/Txt.png',
  avtar : '../assets/images/default_avtar.png',

  panel_layout: 2,


  student_data : {
    email_id: 'guest@examdesk.co',
    email_verified: "Y",
    name: "Guest User",
    phone: "9971839153",
    photo: '../assets/images/default_avtar.png',
    student_disp_id: "GUEST_001",
    student_id: 10001,
    student_name: "Guest User",
    student_sex: "M",
    user_type: 1,
    userid: 1234567890
  },

  legends_status : {
    not_visited : 0,
    not_answered : 0,
    answered : 0,
    marked_review : 0,
    answered_marked_review : 0
  },

  settings: {
    clock_format: "",
    is_all_mandatory_questions: 0,
    is_allow_back_button: 1,
    is_allow_multi_language: 1,
    is_allow_save_test: 1,
    is_grouping_subject: 1,
    is_random_order: 0,
    is_show_answer: 1,
    is_show_marks: 1,
    is_show_question_marks: 1,
    is_show_score: 1,
    is_show_timer: 0,
    is_solution: 1
  }

}