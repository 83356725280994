import { HostListener, Component, OnInit, Renderer, ViewChild, EventEmitter, Output } from '@angular/core';
import { Common } from '../../../services/common.service';
import { MomentModule } from 'ngx-moment';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { OptionsFormatComponent } from '../core/options-format/options-format.component';

import * as globals from './../../../config/globals';
import { Exam } from 'src/app/services/exam.service';
import { PlatformLocation, LocationStrategy } from '@angular/common';
import { PanelCalculatorComponent } from '../panel-layout1/panel-calculator/panel-calculator.component';
import { HttpClient } from '@angular/common/http';
import { timingSafeEqual } from 'crypto';
import LZString from '../../../lz-string.js';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';

declare var $;

@Component({
  selector: 'app-panel-layout1',
  templateUrl: './panel-layout1.component.html',
  styleUrls: ['./panel-layout1.component.scss']
})

export class PanelLayout1Component implements OnInit {


  globals: any = globals.globals;

  language: string = 'English';
  loading: boolean = true;
  testExpire: boolean = false;
  rightPallete: string = '';
  leftPallete: string = '';
  palleteToggle: boolean = true;
  palleteCaret: string = 'right';
  subjects: any;
  is_neet_test: any;
  test_settings: any;
  ques_data: any;
  test_data: any;
  overlayShow: boolean = false;
  overlayMainMessage: any;
  overlayHelpMessage: any;
  testSubmitted: boolean = false;
  testSubmittedSuccess: boolean = false;
  attemptedQues: any;
  notVisitedQuestion: any[] = [];
  notAnsweredQuestion: any[] = [];
  answeredQuestion: any[] = [];
  notAnsweredMarked: any[] = [];
  answeredAndMarked: any[] = [];
  manadatoryQuestion: boolean = false;
  viewScreen: any = "View Full Screen"
  viewFullScreen: boolean = true;
  methodToBeInvoked: any
  formattedTimeLeft: any;
  submitTimestamp: any;
  quesAttemptTime: any;
  quesList: any = [];
  selectedLanguageObtained: any = "1";
  multiLanguageQuesList: any = [];
  currentQuesNo: any;

  activeQuesNo: number = 1;
  activeQuesData: any;
  questionStatus = [];
  questionPallete = [];
  total_questions: any;
  leftTimeToExpire: any;
  leftTimeToSubmit: any;
  calculatorBtnText: any = 'Show Calculator'

  activeSubject: any;
  groupQues: any;
  quesNoCount: number = 0;
  internetConnectivityErr: boolean = false;

  finalSelectedFile: any = {};
  noFileSelectedError: boolean = false;
  uploadFileResponse: any;
  question_file_mapping: any = {};
  fileArray: File;

  question_fileV2_mapping: any = {};
  uploadedFiles: any = {}


  @ViewChild("answerOption") OptionsFormatComponent: OptionsFormatComponent;
  @ViewChild(PanelCalculatorComponent) calcObj: PanelCalculatorComponent = new PanelCalculatorComponent();
  @Output() processResult = new EventEmitter<string>();

  interval: NodeJS.Timer;
  testTimer: string;
  student_data: any;
  active_ques_attempt_time: number = 0;
  ques_timer_state: any = [];
  server_timestamp: any;
  test_end_timestamp: any;
  is_test_completed: string;
  test_attempt_data: any;
  overlayMainIcon: string;
  showApiError: boolean = false;
  apiError: any;
  testSettings: any;
  que_start_timestamp: number;
  isPassageOverflow: boolean = false;
  passageFullActive: boolean = false;
  viewport_width: any;
  limitType: any;
  answerLimit: any;
  showHideCalc: boolean = false;
  checkIfQuesExists: boolean = false;
  selectedSubject: any;
  languageSelected: any;
  multiLanglist: any = [];
  showAlertPopup: boolean = false;
  alertPopUpCount: number = 0;
  legalAlertRemaining: number;
  showUploadBox: boolean = false;
  selectedFileName: any;
  displayAlertPopupMessage: string = '';

  displayAlertPopup: boolean = false;

  @HostListener('document:contextmenu', ['$event'])
  onDocumentRightClick(event) {
    event.preventDefault();
  }

  @HostListener('document:backbutton', ['$event'])
  onPopState(event) {
    event.preventDefault();
   // console.log(event);
  }



   @HostListener('window:blur', ['$event'])
  onPageLeave(event) {
   // console.log(event);
    if (this.testSettings.panel_leave_limit != undefined && this.testSettings.panel_leave_limit > 0 && !this.showUploadBox) {
      this.legalAlertRemaining = this.legalAlertRemaining - 1;
      this.showAlertPopup = true;
    }
  } 


  constructor(
    private renderer: Renderer,
    public common: Common,
    public exam: Exam,
    private router: Router,
    public api: ApiService,
    public moment: MomentModule,
    public pltLocation: PlatformLocation,
    public locationStrtgy: LocationStrategy,
    private http: HttpClient

  ) {
    this.testSettings = JSON.parse(sessionStorage.getItem('test_settings'));
  }

  ngOnInit() {
    this.legalAlertRemaining = parseInt(this.testSettings.panel_leave_limit);
   // this.legalAlertRemaining = 100;
    this.viewport_width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    //console.log(this.viewport_width + ' Pixel');
    if (this.viewport_width < 576) {
      this.toggle_pallete();
    }
   // console.log('ques length', JSON.parse(sessionStorage.getItem('ques_data')).length);
    if (sessionStorage.getItem('ques_data') == undefined || sessionStorage.getItem('test_settings') == undefined || sessionStorage.getItem('subjects') == undefined) {
      this.checkIfQuesExists = true;
      this.router.navigate(['/']);
    }


    else {
      this.test_data = JSON.parse(sessionStorage.getItem('test_data'));
      this.is_test_completed = sessionStorage.getItem('is_test_complete');
      if (this.test_data.is_multi_lingual == 1) {
        this.languageSelected = JSON.parse(sessionStorage.getItem('selected-language'));
       // console.log('selected lang from instructions are:', this.languageSelected);
        var questionList = JSON.parse(sessionStorage.getItem('language-wise-questions'));
        this.multiLanguageQuesList = questionList;
      //  console.log(questionList);

        this.fetchQuestionsBasedOnLanguage(questionList);

      }
      else {
        let saved = JSON.parse(sessionStorage.getItem('is_data_compress'));
        if(saved == 1) {
          this.ques_data = JSON.parse(LZString.decompress(sessionStorage.getItem('ques_data')));
        } else {
          this.ques_data = JSON.parse(sessionStorage.getItem('ques_data'));
        }
      }

    //  console.log(this.ques_data);
      if (this.is_test_completed == '1') {
        this.overlayShow = true;
        this.overlayMainIcon = '<i class="fas fa-exclamation-triangle text-red"></i>';
        this.overlayMainMessage = 'Test is submitted or expired';
        this.overlayHelpMessage = 'This attempt has already been submitted.';
      }
      this.subjects = JSON.parse(sessionStorage.getItem('subjects'));
      this.is_neet_test = sessionStorage.getItem('is_neet_test');
      this.test_settings = JSON.parse(sessionStorage.getItem('test_settings'));
      //  this.ques_data = JSON.parse(sessionStorage.getItem('ques_data'));
      if (this.ques_data.length) {
        this.checkIfQuesExists = false;
      }
      else {
        this.checkIfQuesExists = true;
        this.router.navigate(['/noQues']);
      }

      this.student_data = JSON.parse(sessionStorage.getItem('student_data'));
      this.test_attempt_data = JSON.parse(sessionStorage.getItem('attempt_data'));

      this.total_questions = this.ques_data.length;
      if (this.ques_data.length) {
        if (this.is_neet_test == 1) {
          this.subjects = this.subjects.map(data => ({...data, subject_name: data.subject_name+" - "+data.section_title, subject_id: data.subject_id+","+data.section_id}))
          this.groupQues = this.subjects;
          this.groupQues.forEach(obj => {
            obj.questions = [];
          });
          var subjectIds = this.subjects.map(obj => {
            return obj.subject_id;
          });
          this.ques_data.forEach((obj, index) => {
            if (subjectIds.indexOf(obj.subject_id+","+obj.section_id) > -1) {
              var temp = this.groupQues.find(ob => (ob.subject_id == obj.subject_id+","+obj.section_id));
              temp.questions.push(obj);
            }
          });
         // console.log('after grouping:', this.groupQues);
          this.groupQues.forEach((key, index) => {
            key.questions.forEach((k, val) => {
              k.questionNo = this.quesNoCount;
              this.quesList.push(k);
              this.quesNoCount = this.quesNoCount + 1;
            });
          });
          this.selectedSubject = this.groupQues[0].subject_id;
          this.ques_data = this.quesList;
         // console.log('new ques listing', this.ques_data);
        } else if (this.testSettings.is_grouping_subject == 1) {
          this.groupQues = this.subjects;
          this.groupQues.forEach(obj => {
            obj.questions = [];
          });
          var subjectIds = this.subjects.map(obj => {
            return obj.subject_id;
          });
          this.ques_data.forEach((obj, index) => {
            if (subjectIds.indexOf(obj.subject_id) > -1) {
              var temp = this.groupQues.find(ob => ob.subject_id == obj.subject_id);
              temp.questions.push(obj);
            }
          });
         // console.log('after grouping:', this.groupQues);
          this.groupQues.forEach((key, index) => {
            key.questions.forEach((k, val) => {
              k.questionNo = this.quesNoCount;
              this.quesList.push(k);
              this.quesNoCount = this.quesNoCount + 1;
            });
          });
          this.selectedSubject = this.groupQues[0].subject_id;
          this.ques_data = this.quesList;
         // console.log('new ques listing', this.ques_data);
        }
        else {
          this.ques_data.forEach((k, index) => {
            k.questionNo = index;
          });
        };

      //  console.log('without groupung questions', this.ques_data);
        this.setup_panel();
      }
    }
  }

  fetchQuestionsBasedOnLanguage(questions) {
    if (questions.length) {
      this.multiLanglist = questions;
      var language = questions.find(obj => obj.id == this.languageSelected);
      this.selectedLanguageObtained = language.id;
      //console.log('ng model :',this.selectedLanguageObtained);
      this.ques_data = language.ques_data;
    }
    else {
      let saved = JSON.parse(sessionStorage.getItem('is_data_compress'));
      if(saved == 1) {
        this.ques_data = JSON.parse(LZString.decompress(sessionStorage.getItem('ques_data')));
      } else {
        this.ques_data = JSON.parse(sessionStorage.getItem('ques_data'));
      } 
      /* this.ques_data = JSON.parse(sessionStorage.getItem('ques_data')); */
    }
    // sessionStorage.setItem('selected-language-question-list',JSON.stringify(this.ques_data));
    //console.log('question data:',this.ques_data);
  }
  

  changeLanguageSelected(val) {
   // console.log('on change:', val);
    sessionStorage.setItem('language_id', val);
    var questionData = this.multiLanglist.find(obj => obj.id == val).ques_data;
    this.ques_data = questionData;
  //  console.log('active ques no:', this.activeQuesData);
    this.activeQuesNo = this.activeQuesData.questionNo;
    var test = document.getElementById("question-number-"+this.activeQuesNo);
      if(test != null) {
        test.scrollIntoView();
      }
    // sessionStorage.setItem('selected-language-question-list',JSON.stringify(this.ques_data));
    this.fetchQuesOnLangChange(val);
  }

  fetchQuesOnLangChange(id) {
    var lang = this.multiLanglist.find(ob => ob.id == id);
    this.ques_data = lang.ques_data;
    this.ques_data.forEach((key, val) => {
      key.questionNo = val;
    })
    this.activeQuesData = this.ques_data[this.activeQuesNo];
  }

  openUploadBox(){
    this.showUploadBox = true;
    this.finalSelectedFile = {};
  }
  closeUploadBox(){
    this.showUploadBox = false;
    this.noFileSelectedError = false;
    this.finalSelectedFile = {};
  }

  fillFile(){
    setTimeout(()=>{
      var uploadedFile = (<HTMLInputElement>document.getElementById('uploadFileControl')).files;
      let fileSizeOK = true;
      for (let index = 0; index < uploadedFile.length; index++) {
        const element = uploadedFile[index];
        let size = element.size;
        if(size > 50000000){
          fileSizeOK = false;
        }
      }
      if(fileSizeOK){
        if(uploadedFile.length){
          this.fileArray = Object.assign(uploadedFile[0]);
          this.finalSelectedFile = this.generateFilePreview(this.fileArray);
          // this.finalSelectedFile = fileArray;
          // console.log('file arr:',this.finalSelectedFile)
          }
      } else {
        alert("You can not select filesize greater than 50 MB");
      }
      
    },500)
  }

  generateFilePreview(file){
    var obj ={
      "name": file.name,
      "lastModified": file.lastModified,
      "lastModifiedDate": file.lastModifiedDate,
      "webkitRelativePath":file.webkitRelativePath,
      "size":file.size,
      "type":file.type
    }
    return obj;
  }

  checkFileFullyUploaded(){
    if(Object.entries(this.finalSelectedFile).length){
      return false;
    }
    else {
      return true;
    }
  }

 
  deselectFile(){
    this.selectedFileName = '';
    var attempt_data = JSON.parse(sessionStorage.getItem('attempt_data'));
    console.log('attempt data',attempt_data);
    var file_key = 'file_'+ this.activeQuesData.ques_id;
    if(this.question_file_mapping.hasOwnProperty(file_key)){
      delete this.question_file_mapping[file_key];
    }
    if(attempt_data != null){
      let fileObj = JSON.parse(attempt_data.answer_json);
      fileObj.files = Object.entries(this.question_file_mapping).length ? Object.assign({},this.question_file_mapping): {};
      this.test_attempt_data.answer_json = JSON.stringify(fileObj);
      sessionStorage.setItem('attempt_data',JSON.stringify(this.test_attempt_data));
    }
    this.saveTestStatus();
  }

  removeFile(index){
    var file_key = 'file_'+ this.activeQuesData.ques_id;
    var attempt_data = sessionStorage.getItem('attempt_data') != null ? JSON.parse(sessionStorage.getItem('attempt_data')) : null;
    var fileObj =attempt_data != null ? JSON.parse(attempt_data.answer_json): null;
    if(this.question_fileV2_mapping.hasOwnProperty(file_key)){
      this.uploadedFiles.splice(index, 1);
      this.question_fileV2_mapping[file_key] = this.uploadedFiles;
    }
    if(attempt_data != null){
      let fileObj = JSON.parse(attempt_data.answer_json);
      fileObj.filesV2 = Object.entries(this.question_fileV2_mapping[file_key]).length ? Object.assign({},this.question_fileV2_mapping): {};
      this.test_attempt_data.answer_json = JSON.stringify(fileObj);
      sessionStorage.setItem('attempt_data',JSON.stringify(this.test_attempt_data));
    }
    this.saveTestStatus();
  }

  /* uploadFile(){
    if(Object.entries(this.finalSelectedFile).length){    
    this.api.uploadFileApi(this.finalSelectedFile).subscribe(data =>{
      this.uploadFileResponse = {};
      this.uploadFileResponse = data;
      if(this.uploadFileResponse.validate){
        this.noFileSelectedError = false;
        var file_ques_key = 'file_'+ this.activeQuesData.ques_id;
       if(Object.entries(this.question_file_mapping).length){
          if(!(this.question_file_mapping.hasOwnProperty(file_ques_key))){
            this.question_file_mapping[file_ques_key] = '';
          }
         
         this.question_file_mapping[file_ques_key] = this.uploadFileResponse.file_id + '#'+ this.uploadFileResponse.source_url;
      }
      else {
        this.question_file_mapping[file_ques_key] = '';
        this.question_file_mapping[file_ques_key] = this.uploadFileResponse.file_id + '#'+ this.uploadFileResponse.source_url;
      }
      this.uploadFileToAWS(this.uploadFileResponse);
      this.checkIfFileIsUploaded();
      this.saveTestStatus();
      }
    },err =>{

    }) 
  }
  else{
    this.noFileSelectedError = true;
  }
    
  } */

  uploadFile(){
    if(Object.entries(this.finalSelectedFile).length){    
      this.api.uploadFileApi(this.finalSelectedFile).subscribe(data =>{
        this.uploadFileResponse = {};
        this.uploadFileResponse = data;
        var temp = [];
        if(this.uploadFileResponse.validate){
          this.noFileSelectedError = false;
          var file_ques_key = 'file_'+ this.activeQuesData.ques_id;
          if(this.question_fileV2_mapping.hasOwnProperty(file_ques_key)){
            temp = this.question_fileV2_mapping[file_ques_key];
            temp.push(this.uploadFileResponse.file_id + '#'+ this.uploadFileResponse.source_url)
            this.question_fileV2_mapping[file_ques_key] = temp;
          } else {
            temp = [];
            temp.push(this.uploadFileResponse.file_id + '#'+ this.uploadFileResponse.source_url)
            this.question_fileV2_mapping[file_ques_key] = temp;
          }
          console.log('V2 : ', this.question_fileV2_mapping);
          this.showUploadBox = false;
          this.uploadFileToAWS(this.uploadFileResponse);
          //this.checkIfFileIsUploaded();
          this.checkIfFilesAreUploaded(this.activeQuesData.ques_id);
          this.saveTestStatus();
        }
      },err =>{

      }) 
    }
    else{
      this.noFileSelectedError = true;
    }
    
  }

  //upload file to s3 bucket
  uploadFileToAWS = async function(response){ 
    console.log('Response',response);
    console.log('file arary:',this.fileArray)
    let file_path = URL.createObjectURL(this.fileArray);
    let fileObject = await fetch(file_path).then(r => r.blob());  
    let postData = {
      s3_url: response.s3_url,
      type: this.fileArray.type
    }
    this.api.uploadFileInAWS(fileObject, postData).subscribe(data =>{
     // console.log(data);
      this.showUploadBox = false;
    },err =>{
     // console.log(err)
    })
  }

  checkIfFileIsChoosen(){
    if(Object.entries(this.finalSelectedFile).length){
      return true;
    }
    else {
      return false;
    }
  }

  closePopUp() {
    this.displayAlertPopup = false;
    this.showAlertPopup = false;
     var el = document.body;
    this.methodToBeInvoked = el['requestFullscreen'] || el['msRequestFullscreen'] || el['mozRequestFullScreen'];
    if (this.methodToBeInvoked) {
      this.methodToBeInvoked.call(el);
    } 
  }
  ngAfterViewInit() {
    let el = document.getElementById('passageWrapper');
    if (el != undefined)
      el.addEventListener('DOMSubtreeModified', this.resizePassage, false);
  }

  openCalculatorModal() {
    this.showHideCalc = !(this.showHideCalc);
    if (this.showHideCalc) {
      this.calculatorBtnText = 'Hide Calculator';
    }
    else {
      this.calculatorBtnText = 'Show Calculator';
    }
  }

  setup_panel() {
    this.activeQuesData = this.ques_data[this.activeQuesNo - 1];
    this.quesAttemptTime = this.ques_data[this.activeQuesNo - 1].attempt_duration;
    if (this.activeQuesData.attempt_duration >= 60) {
      this.quesAttemptTime = (this.activeQuesData.attempt_duration / 60) + ' h ' + (this.activeQuesData.attempt_duration % 60) + ' m'
    }
    else {
      this.quesAttemptTime = this.activeQuesData.attempt_duration + ' mins';
    }


    this.limitType = this.ques_data[this.activeQuesNo - 1].is_ans_limit;
    this.answerLimit = this.ques_data[this.activeQuesNo - 1].ans_limit;

  //  console.log('attempted ques time:', this.quesAttemptTime);

    this.ques_data.forEach(el => {
      //this.questionStatus.push({'ques_id':el.ques_id, 'status_number':1, 'status_class':'nta-legend-not-visited'});
      this.questionStatus[el.ques_id] = { 'status_number': 1, 'answer': -1, 'status_class': 'nta-legend-not-visited' };
    });

    this.questionStatus[this.activeQuesData.ques_id] = { 'status_number': 2, 'answer': '-1', 'status_class': 'nta-legend-not-answered' };

    this.api.server_time().subscribe(res => {
      this.server_timestamp = res['timestamp'];
      if (this.test_attempt_data == undefined) {
       // console.log('end timestamp is:', this.test_data.test_end_timestamp);
        this.leftTimeToExpire = parseInt(this.test_data.test_end_timestamp) - parseInt(res['timestamp']);
        switch (this.test_data.test_type_id) {
          case 1: //Mock Test
            this.leftTimeToSubmit = (this.test_data.duration < this.leftTimeToExpire) ? (parseInt(this.test_data.duration) * 60) : this.leftTimeToExpire;
            this.test_end_timestamp = this.server_timestamp + this.leftTimeToSubmit;
            break;
          case 2: //Practice Test
            // this.leftTimeToSubmit = 0;
            this.leftTimeToSubmit = parseInt(this.test_data.duration) != 0 ? parseInt(this.test_data.duration) * 60 : 0;
            this.test_end_timestamp = parseInt(this.test_data.duration) != 0 ? this.server_timestamp + parseInt(this.test_data.duration) * 60 : null;
            break;
          case 3: //Classroom Exam
          case 4: //Online Exam
            this.leftTimeToSubmit = this.leftTimeToExpire;
            this.test_end_timestamp = this.server_timestamp + this.leftTimeToSubmit;
            break;
        }
        var date = new Date(this.leftTimeToSubmit);

      }
      else if (this.test_data.test_type_id > 2) { //online and classroom
        this.leftTimeToSubmit = parseInt(this.test_attempt_data.test_end_timestamp) - parseInt(this.server_timestamp);
        //console.log('left time to submit in online exams:', this.leftTimeToSubmit)
        //  this.leftTimeToSubmit = Math.floor(this.leftTimeToSubmit / 3600 ) % 24 + ':'+Math.floor(this.leftTimeToSubmit / 60) % 60+ ':'+ this.leftTimeToSubmit % 60;   
      }
      else if (this.test_data.test_type_id == 1) { //mock
        let attempt_start_ts = parseInt(this.test_attempt_data.attempt_start_timestamp);
        let test_duration = parseInt(this.test_data.duration) * 60;
        let attempt_end_timestamp = attempt_start_ts + test_duration;
        let test_end_timestamp = parseInt(this.test_attempt_data.test_end_timestamp);
        this.test_end_timestamp = (attempt_end_timestamp < test_end_timestamp) ? attempt_end_timestamp : test_end_timestamp;
        this.leftTimeToSubmit = this.test_end_timestamp - this.server_timestamp;
      }
      else {
        if (parseInt(this.test_data.duration) > 0) {
          let attempt_start_ts = parseInt(this.test_attempt_data.attempt_start_timestamp);
          let test_duration = parseInt(this.test_data.duration) * 60;

          this.test_end_timestamp = attempt_start_ts + test_duration;
          this.leftTimeToSubmit = this.test_end_timestamp - this.server_timestamp;
        }
        else {
          this.leftTimeToSubmit = 0;
          this.test_end_timestamp = null;
        }

      }

      this.loading = false;
      if (this.leftTimeToSubmit <= 0 && parseInt(this.test_data.duration) != 0 && this.test_data.duration != '') {
      /*   console.log(this.leftTimeToSubmit);
        console.log(this.test_data.duration) */
        alert('This test is already expired');
        this.submitTest();
      }

      else {
        // if (this.test_data.test_type_id != 2) {
        if (parseInt(this.test_data.duration) > 0) {
          this.startTimer();
        }
        // }
        this.initQuesStatus();
        //  this.calculateLegendStatus();
      }

    },
      errors => {

      });

    //Question Time Starts
    this.que_start_timestamp = (new Date).getTime();
  }


  initQuesStatus() {
    let data = JSON.parse(sessionStorage.getItem('attempt_data'));
    let saved = JSON.parse(sessionStorage.getItem('is_data_compress'));
    if(saved == 1) {
      var ques_data = JSON.parse(LZString.decompress(sessionStorage.getItem('ques_data')));
    } else {
      var ques_data = JSON.parse(sessionStorage.getItem('ques_data'));
    } 
    
    if (data != undefined) {
      let attempt_data = JSON.parse(data.answer_json);
      let ques_ids = Object.values(attempt_data.questions.split('#'));
      let attempt_time_arr = attempt_data.attempt_time.split('#');
      let answers_arr = attempt_data.answers.split('#');
      let status_arr = attempt_data.question_state.split('#');
      let sub_answers = attempt_data.subjective_answer;
     // console.log(sub_answers);

      ques_data.forEach(ques => {

        let index = ques_ids.indexOf(ques.ques_id.toString());
        let ans = answers_arr[index];
        let ques_type_id = ques.ques_type_id;

        if (ques_type_id == 2) {
          let sub_index = ans.split('_')[1];
          ans = sub_answers[sub_index];
        }

        let status = 'nta-legend-not-visited';
        switch (status_arr[index]) {
          case '1': status = 'nta-legend-not-visited'; break;
          case '2': status = 'nta-legend-not-answered'; break;
          case '3': status = 'nta-legend-not-answered-review'; break;
          case '4': status = 'nta-legend-answered'; break;
          case '5': status = 'nta-legend-answered-review'; break;
        }

      

        this.questionStatus[ques.ques_id] = { 'status_number': parseInt(status_arr[index]), 'answer': ans, 'status_class': status };
        this.ques_timer_state[ques.ques_id] = parseInt(attempt_time_arr[index]);
      });
    }

    this.calculateLegendStatus();
  }

  startTimer() {
    this.testTimer = '';
    this.leftTimeToSubmit -= 2; //2 Seconds Network Latency
    this.interval = setInterval(() => {
      this.leftTimeToSubmit -= 1;
      this.testTimer = Math.floor(this.leftTimeToSubmit / 3600)
        + ' : ' + ("0" + (Math.floor(this.leftTimeToSubmit / 60) % 60)).slice(-2)
        + ' : ' + ("0" + this.leftTimeToSubmit % 60).slice(-2);
      /*  switch (this.testSettings.clock_format) {
         case 1: // hh:mm:s
           this.testTimer = Math.floor(this.leftTimeToSubmit / 3600)
             + ' : ' + ("0" + (Math.floor(this.leftTimeToSubmit / 60) % 60)).slice(-2)
             + ' : ' + ("0" + this.leftTimeToSubmit % 60).slice(-2);
           break;
         case 2: //hh:mm
           this.testTimer = Math.floor(this.leftTimeToSubmit / 3600) +':'+("0" + (Math.floor(this.leftTimeToSubmit / 60) % 60)).slice(-2)            
           break;
         case 3: //in mins only
           this.testTimer = ("0" + (Math.floor(this.leftTimeToSubmit / 60) % 60)).slice(-2) + ' mins';
           break;
       } */

      if (this.leftTimeToSubmit <= 0) {
        this.submitTest(1);
        clearInterval(this.interval);
      }
    }, 1000);
  }

  loadSubjectWiseQuestion(subject) {
    let prev_ques_id, next_ques_id;
    prev_ques_id = this.activeQuesData.ques_id;
    next_ques_id = this.activeQuesData.ques_data;
    this.changeQueTimer(prev_ques_id, next_ques_id);
    if (this.questionStatus[this.activeQuesData.ques_id].status_number == 1) {
      this.questionStatus[this.activeQuesData.ques_id] = { 'status_number': 2, 'answer': -1, 'status_class': 'nta-legend-not-answered' };
    }
    else if (this.questionStatus[this.activeQuesData.ques_id].status_number == 3 && this.questionStatus[this.activeQuesData.ques_id].answer == '-1') {
      this.questionStatus[this.activeQuesData.ques_id] = { 'status_number': 2, 'answer': -1, 'status_class': 'nta-legend-not-answered' };
    }
    else if (this.questionStatus[this.activeQuesData.ques_id].status_number == 3 && this.questionStatus[this.activeQuesData.ques_id].answer != '-1') {
      this.questionStatus[this.activeQuesData.ques_id].status_number = 4;
      this.questionStatus[this.activeQuesData.ques_id].status_class = 'nta-legend-answered';
    }
    else if (this.questionStatus[this.activeQuesData.ques_id].status_number == 5 && this.questionStatus[this.activeQuesData.ques_id].answer != '-1') {
      this.questionStatus[this.activeQuesData.ques_id].status_number = 5;
      this.questionStatus[this.activeQuesData.ques_id].status_class = 'nta-legend-answered-review';
    }
    this.saveTestStatus();
    // this.loading = true;
   // console.log('selected subject is:', subject);
   if (this.is_neet_test == 1){
    subject = subject.split(",");
    this.activeSubject = this.ques_data.filter(obj => (obj.subject_id == subject[0] && obj.section_id == subject[1]));
   } else {
    this.activeSubject = this.ques_data.filter(obj => obj.subject_id == subject);
   }
    
    this.activeQuesData = this.activeSubject[0];
  }

  nextQuestion(modal='') {
    this.questionStatus[this.activeQuesData.ques_id].status_number = 2;

    if(this.is_neet_test == 1){
      let subject_id = this.activeQuesData.subject_id;
      let section_id = this.activeQuesData.section_id;
      let section_data = this.subjects.find(data => (data.subject_id == subject_id+","+section_id));
      let attempt_questions = section_data.attempt_questions;
      let question_ids = this.ques_data.filter(data => (data.subject_id == subject_id && data.section_id == section_id))
      question_ids = question_ids.map(data => data.ques_id);
      let count = question_ids.reduce((previousValue, currentValue) => (this.questionStatus[currentValue].status_number==4||this.questionStatus[currentValue].status_number==5)?previousValue+1:previousValue, 0)
      if(count == attempt_questions){
        this.displayAlertPopup = true;
        this.displayAlertPopupMessage = 'Maximum number of attemaptable questions for this section is '+attempt_questions+'. To attemp this question please unanswer other questions from this section or move to next section.';
        /* alert('All Questions are Mandatory');  */
        return false;
      }
    }

    let update_ques_id = this.activeQuesData.ques_id;
    let update_ques_type_id = this.activeQuesData.ques_type_id;
    let check = this.updateAnswerStatus(update_ques_id, update_ques_type_id);
    if (!check) {
      return false;
    }
    let prev_ques_id, next_ques_id;
    this.passageFullActive = false;
    
    this.activeQuesNo = this.activeQuesData.questionNo + 1;
    if (this.ques_data[this.activeQuesNo] == undefined) {
      this.activeQuesNo -= 1;
      prev_ques_id = this.activeQuesData.ques_id;
      next_ques_id = this.activeQuesData.ques_id;
    }
    else {
      prev_ques_id = this.activeQuesData.ques_id;
      this.activeQuesData = this.ques_data[this.activeQuesNo];
      next_ques_id = this.activeQuesData.ques_id;

      /* this.activeQuesNo += 1;
      if (this.ques_data[this.activeQuesNo - 1] == undefined) {
        this.activeQuesNo -= 1;
        prev_ques_id = this.activeQuesData.ques_id;
        next_ques_id = this.activeQuesData.ques_id;
      }
      else {
        prev_ques_id = this.activeQuesData.ques_id;
        this.activeQuesData = this.ques_data[this.activeQuesNo - 1];
        next_ques_id = this.activeQuesData.ques_id; */
        this.answerLimit = this.ques_data[this.activeQuesNo].ans_limit;
        this.limitType = this.ques_data[this.activeQuesNo].is_ans_limit;
        this.quesAttemptTime = this.activeQuesData.attempt_duration;
      // console.log('duration for next ques', this.quesAttemptTime);
      // console.log('active ques data:',this.activeQuesData);

      if (this.activeQuesData.attempt_duration >= 60) {
        this.quesAttemptTime = (this.activeQuesData.attempt_duration / 60) + ' h ' + (this.activeQuesData.attempt_duration % 60) + ' m'
      }
      else {
        this.quesAttemptTime = this.activeQuesData.attempt_duration + ' mins';
      }
      //alert(this.activeQuesNo);
      var test = document.getElementById("question-number-"+this.activeQuesNo);
      
        if(test != null) {
          test.scrollIntoView();
        }


    }

    this.changeQueTimer(prev_ques_id, next_ques_id);

    if (this.questionStatus[this.activeQuesData.ques_id].status_number == 1) {
      this.questionStatus[this.activeQuesData.ques_id] = { 'status_number': 2, 'answer': -1, 'status_class': 'nta-legend-not-answered' };
    }
    else if (this.questionStatus[this.activeQuesData.ques_id].status_number == 3 && this.questionStatus[this.activeQuesData.ques_id].answer == '-1') {
      this.questionStatus[this.activeQuesData.ques_id] = { 'status_number': 2, 'answer': -1, 'status_class': 'nta-legend-not-answered' };
    }
    else if (this.questionStatus[this.activeQuesData.ques_id].status_number == 3 && this.questionStatus[this.activeQuesData.ques_id].answer != '-1') {
      this.questionStatus[this.activeQuesData.ques_id].status_number = 4;
      this.questionStatus[this.activeQuesData.ques_id].status_class = 'nta-legend-answered';
    }
    else if (this.questionStatus[this.activeQuesData.ques_id].status_number == 5 && this.questionStatus[this.activeQuesData.ques_id].answer != '-1') {
      this.questionStatus[this.activeQuesData.ques_id].status_number = 5;
      this.questionStatus[this.activeQuesData.ques_id].status_class = 'nta-legend-answered-review';
    }
    this.optimizeMatchMatrix();
    this.calculateLegendStatus();
    this.setSubject(this.activeQuesData);

    if(modal) {
      this.common.openVerticallyCentered(modal)
    }
  }

  checkIfFileIsUploaded(){
    this.selectedFileName = '';
    var file_key = 'file_'+ this.activeQuesData.ques_id;
    var attempt_data = sessionStorage.getItem('attempt_data') != null ? JSON.parse(sessionStorage.getItem('attempt_data')) : null;
    //if(attempt_data != null){
    var fileObj =attempt_data != null ? JSON.parse(attempt_data.answer_json): null;
    if(this.question_file_mapping.hasOwnProperty(file_key)){
      let splitFileArr = this.question_file_mapping[file_key].split('/');
      this.selectedFileName = splitFileArr[splitFileArr.length -1];
      return true;
    }
    else if(attempt_data != null && fileObj.files != undefined && Object.entries(fileObj.files).length){
      var uploadedFiles = fileObj.files;
      var file_key = 'file_' + this.activeQuesData.ques_id;
        if(uploadedFiles.hasOwnProperty(file_key)){ 
            let splitFileArr = uploadedFiles[file_key].split('/');
            this.selectedFileName = splitFileArr[splitFileArr.length - 1];
            this.question_file_mapping = uploadedFiles;
            this.saveTestStatus();
            return true;
        }
        else {
          return false;
        }
    } 
    else {
      return false;
    }
    //}
  }

  checkIfFilesAreUploaded(ques_id)
  {
    this.uploadedFiles = {};
    var file_key = 'file_'+ ques_id;
    var attempt_data = sessionStorage.getItem('attempt_data') != null ? JSON.parse(sessionStorage.getItem('attempt_data')) : null;
   // if(attempt_data != null){
    var fileObj =attempt_data != null ? JSON.parse(attempt_data.answer_json): null;
    if(this.question_fileV2_mapping.hasOwnProperty(file_key)){
      this.uploadedFiles = this.question_fileV2_mapping[file_key];
      return true;
    }
    else if(attempt_data != null && fileObj.filesV2 != undefined && Object.entries(fileObj.filesV2).length){
      var files = fileObj.filesV2;
      var file_key = 'file_' + ques_id;
        if(files.hasOwnProperty(file_key)){ 
            let uploadedFiles = files[file_key];
            this.question_fileV2_mapping[file_key] = uploadedFiles;
            this.saveTestStatus();
            return true;
        }
        else {
          return false;
        }
    } 
    else {
      return false;
    }
    //}
  }

  changeQueTimer(prev_ques_id, next_ques_id) {
    let currentTimestamp = (new Date).getTime();

    this.ques_timer_state[prev_ques_id] = parseInt(this.ques_timer_state[prev_ques_id] || 0) + currentTimestamp - this.que_start_timestamp;

    this.que_start_timestamp = currentTimestamp;
  }

  checkIfAllQuesMandatory() {
    if (this.test_settings.is_all_mandatory_questions == 1) {
      if (this.answeredQuestion.length == 0) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  visitQuestion(queNo) {
    /* if (this.questionStatus[this.activeQuesData.ques_id].status_number < 4 && this.testSettings.is_all_mandatory_questions == 1) {
      alert('All questions are mandatory!')
      return false;
    } */
    // if(this.testSettings.is_allow_back_button != 1){
    //   return false;
    // }
    if (this.activeQuesNo > queNo && this.testSettings.is_allow_back_button != 1) {
      return false;
    }

    var test = document.getElementById("question-number-"+queNo);
    
      if(test != null) {
        test.scrollIntoView();
      }

    var test = document.getElementById("question-number-"+queNo);
    

    this.passageFullActive = false;
    let prev_ques_id, next_ques_id;
    this.activeQuesNo = queNo;
    prev_ques_id = this.activeQuesData.ques_id;
    this.activeQuesData = this.ques_data[this.activeQuesNo - 1];
    this.optimizeMatchMatrix();
    this.limitType = this.ques_data[this.activeQuesNo - 1].is_ans_limit;
    this.answerLimit = this.ques_data[this.activeQuesNo - 1].ans_limit;
    if (this.activeQuesData.attempt_duration >= 60) {
      this.quesAttemptTime = (this.activeQuesData.attempt_duration / 60) + ' h ' + (this.activeQuesData.attempt_duration % 60) + ' m'
    }
    else {
      this.quesAttemptTime = this.activeQuesData.attempt_duration + 'mins';
    }
    next_ques_id = this.activeQuesData.ques_id;
    this.changeQueTimer(prev_ques_id, next_ques_id);
    if (this.questionStatus[this.activeQuesData.ques_id].status_number == 1) {
      this.questionStatus[this.activeQuesData.ques_id] = { 'status_number': 2, 'answer': -1, 'status_class': 'nta-legend-not-answered' };
    }
    this.calculateLegendStatus();

    if (this.viewport_width < 576) {
      this.toggle_pallete();
    }
    this.setSubject(this.activeQuesData);

  }

  setSubject(activeQues) {
    if(this.is_neet_test==1){
      this.selectedSubject = activeQues.subject_id+","+activeQues.section_id;
    } else {
      this.selectedSubject = activeQues.subject_id;
    }
    
  }

  clearResponse() {
    /* this.finalSelectedFile = {};
    this.uploadFileResponse = {}; */
    switch (this.activeQuesData.ques_type_id) {
      case 1:
        this.OptionsFormatComponent.answers[this.activeQuesData.ques_id] = '-1';
        break;
      case 2:
        this.OptionsFormatComponent.answers[this.activeQuesData.ques_id] = '';
        break;
      case 3:
        this.OptionsFormatComponent.matrixAnswer[this.activeQuesData.ques_id] = [];
        break;
      case 5:
        this.OptionsFormatComponent.answers[this.activeQuesData.ques_id] = '-1';
        break;
      case 6:
        this.OptionsFormatComponent.matrixAnswer[this.activeQuesData.ques_id] = [];
        break;
      case 7:
        this.OptionsFormatComponent.matrixAnswer[this.activeQuesData.ques_id] = [];
        break;
    }
    this.questionStatus[this.activeQuesData.ques_id] = { 'status_number': 2, 'answer': -1, 'status_class': 'nta-legend-not-answered' };
  }

  optimizeMatchMatrix(){
   // console.log('active ques dtaa:',this.activeQuesData);
    if(this.activeQuesData.ques_type_id == 6 || this.activeQuesData.ques_type_id == 7){
      var nonEmptyChoices = this.activeQuesData.options.filter(obj => obj.answer_option != ''); //non empty left choices
      var emptyChoices = this.activeQuesData.options.filter(obj => obj.answer_option == ''); //empty left 
      this.activeQuesData.options = nonEmptyChoices;
      if(emptyChoices.length){
        emptyChoices.forEach((obj,val)=>{
          this.activeQuesData.options.push(obj);
        })
      }
    }

  }

  calculateLegendStatus(save = true) {

    globals.globals.legends_status = {
      not_visited: 0,
      not_answered: 0,
      answered: 0,
      marked_review: 0,
      answered_marked_review: 0
    };

    this.questionStatus.forEach(el => {
      switch (el['status_number']) {
        case 1: globals.globals.legends_status.not_visited++; break;
        case 2: globals.globals.legends_status.not_answered++; break;
        case 4: globals.globals.legends_status.answered++; break;
        case 3: globals.globals.legends_status.marked_review++; break;
        case 5: globals.globals.legends_status.answered_marked_review++; break;
      }
    });

    if (save && this.is_test_completed != '1') {
      this.saveTestStatus();
    }

    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
  }
  saveTestStatus(submit = 0) {
    //console.log(this.question_file_mapping)
    this.internetConnectivityErr = false;
    let ques_str = '', ques_time_str = '', answer_str = '', ques_state_str = '';
    sessionStorage.setItem('language_id', this.selectedLanguageObtained);
  
    let sub_index = 0;
    let sub_answers = [];
    for (const index in this.ques_data) {
      if (this.ques_data.hasOwnProperty(index)) {
        const el = this.ques_data[index];
        let ques_type_id = el.ques_type_id;
        let ques_id = el.ques_id;
        ques_str = ques_str + ques_id + '#';
        ques_state_str = ques_state_str + this.questionStatus[ques_id].status_number + '#';
        if(ques_type_id == 2) {
            answer_str = answer_str + 'subjective_' + sub_index + '#';

          //  answer_str = answer_str +  decodeURIComponent(escape(window.atob(this.questionStatus[ques_id].answer)))  + '#';
            var attempt_data = this.test_attempt_data != null ? JSON.parse(this.test_attempt_data.answer_json) :null;
         // if(attempt_data !=null){
          if(attempt_data != null && attempt_data.filesV2 != undefined && Object.entries(attempt_data.filesV2).length )
          {
              console.log("1");
              var files = attempt_data.filesV2;
              var file_key = 'file_'+ ques_id;
              if(files.hasOwnProperty(file_key)){
                if(this.question_fileV2_mapping.hasOwnProperty(file_key)){
                  this.question_fileV2_mapping[file_key] = this.question_fileV2_mapping[file_key];
                } else {
                  this.question_fileV2_mapping[file_key] = files[file_key];
                }
              }
              if(files.hasOwnProperty(file_key) && (this.questionStatus[ques_id].answer == '-1' || this.questionStatus[ques_id].answer == 'A File has been uploaded')){
                var msg = btoa('A File has been uploaded');
                sub_answers.push(msg);
              }    
              else {
                sub_answers.push(this.questionStatus[ques_id].answer);
              }       
          }
          /* else if(Object.entries(this.question_file_mapping).length){
              var file_key = 'file_'+ ques_id;
              if(this.question_file_mapping.hasOwnProperty(file_key) && this.questionStatus[ques_id].answer == '-1'){
                var msg = btoa('A File has been uploaded');
                sub_answers.push(msg);
              }
              else {
                sub_answers.push(this.questionStatus[ques_id].answer);
              }
          } */ else if(Object.entries(this.question_fileV2_mapping).length){
            console.log("2");
            var file_key = 'file_'+ ques_id;
            if(this.question_fileV2_mapping.hasOwnProperty(file_key) && this.questionStatus[ques_id].answer == '-1'){
              var msg = btoa('A File has been uploaded');
              sub_answers.push(msg);
            }
            else {
              sub_answers.push(this.questionStatus[ques_id].answer);
            }
          }
          else {
            console.log("3");
            sub_answers.push(this.questionStatus[ques_id].answer);
          } 
          sub_index++;
        }
        else {
          answer_str = answer_str + this.questionStatus[ques_id].answer + '#';
        }
        //  sub_answers.push(this.questionStatus[ques_id].answer);


       // }
       
        let time = (this.ques_timer_state[ques_id] == undefined) ? 0 : this.ques_timer_state[ques_id];
        ques_time_str = ques_time_str + time + '#';
      }   
     /*  if(ques_type_id == 2 && Object.entries(this.finalSelectedFile).length){
        var file_ques_key = 'file_' + el.ques_id
      }     */ 
    }

    ques_str = ques_str.slice(0, -1);
    ques_state_str = ques_state_str.slice(0, -1);
    ques_time_str = ques_time_str.slice(0, -1);
    answer_str = answer_str.slice(0, -1);

    let finalStateData: any = {
      "questions": ques_str,
      "attempt_time": ques_time_str,
      "answers": answer_str,
      "question_state": ques_state_str,
      "student_id": this.student_data.student_id,
      "test_id": this.test_data.test_id,
      "isComplete": submit,
      "totalTimeLeft": this.leftTimeToSubmit,
      "testType": this.test_data.test_type_id,
      "exam_schd_id": this.student_data.student_disp_id,
      "test_start_time": this.server_timestamp,
      "test_end_time": this.test_end_timestamp,
      "user_id": this.student_data.userid,
      "student_disp_id": this.student_data.student_disp_id,
      "student_name": (this.student_data.user_type == 1) ? this.student_data.student_name : this.student_data.name,
      "student_contact": this.student_data.phone,
      "subjective_answer": sub_answers,
      "language_id": this.selectedLanguageObtained,
      "files": this.question_file_mapping,
      "filesV2": this.question_fileV2_mapping
    };
    if (finalStateData.question_state !== undefined && finalStateData.question_state !== '') {
      var quesState = finalStateData.question_state.split("#");
      this.notVisitedQuestion = quesState.filter(k => k == "1");
      this.notAnsweredQuestion = quesState.filter(obj => obj == "2");
      this.answeredQuestion = quesState.filter(ob => ob == '4');
      this.notAnsweredMarked = quesState.filter(key => key == '3');
      this.answeredAndMarked = quesState.filter(value => value == '5');

    }

    /* if (this.testSettings.is_all_mandatory_questions == 1) {
      if (this.notVisitedQuestion.length || this.notAnsweredQuestion.length) {
        this.manadatoryQuestion = true;
      }
      else {
        this.manadatoryQuestion = false;
      }
    } */
   // console.log(finalStateData);

    sessionStorage.setItem('test_status', JSON.stringify(finalStateData));


    if (this.student_data.userid == 1234567890) {
      /*
      ** If student is a guest user (not registered)
      ** Preventing State Save API from being called
      */
      if (submit) {
        //Evaluating Result if test is submitted.
        this.testSubmittedSuccess = true;
        this.processResult.emit();
      }
    }
    else {
      this.api.saveTestStatus(submit).subscribe(res => {
        if (submit) {
          this.testSubmittedSuccess = true;
          this.processResult.emit();
        }
      },
        error => {
         // console.log(error);
         /*  if (error.error.errors.code == 'ACC007' || error.error.errors.code == 'ACC008' || error.error.errors.code == 'ACC009' || error.error.errors.code == 'ACC011') {
            this.apiError = error;
            this.showApiError = true;
          } */
         // console.log('main error',error.error);
          if(error.error.errors != undefined){
            if (error.error.errors.code == 'ACC007' || error.error.errors.code == 'ACC008' || error.error.errors.code == 'ACC009' || error.error.errors.code == 'ACC011') {
              this.apiError = error;
              this.showApiError = true;
            }
        }
        else{
          this.internetConnectivityErr = true;
        }
          
        });
    }
  }

  checkInternetConnnection(){
    this.internetConnectivityErr = false;
    this.api.getCurrentTimestamp().subscribe(data =>{
      let temp:any = {};
       temp = data;
        if(temp.timestamp != undefined){
          this.internetConnectivityErr = false;
        }
        
     
    }, err =>{
      console.log('ts error:',err);
      this.internetConnectivityErr = true;
    })
  }
  decodeOption(opt) {
    return decodeURIComponent(escape(window.atob(opt)));
  }
  submitTest(expire = 1) {
    this.showAlertPopup = false;
    console.log("Questions Answered", this.answeredQuestion.length);
    console.log("Questions Marked And Answered", this.answeredAndMarked.length);
    let total_answered = this.answeredQuestion.length + this.answeredAndMarked.length;
    if (expire!=-1 && total_answered != this.ques_data.length && this.test_settings.is_all_mandatory_questions == 1 && this.leftTimeToSubmit >= 2) {
      /* alert('All questions are manadatory !'); */
      this.displayAlertPopup = true;
      this.displayAlertPopupMessage = 'All Questions are Mandatory !';
      return;
    }
    else {
      sessionStorage.setItem('language_id', this.selectedLanguageObtained);
      this.api.server_time().subscribe(resp => {
        this.submitTimestamp = resp['timestamp'];
        sessionStorage.setItem('test_end_time', JSON.stringify(this.submitTimestamp));
        if (expire == 1) {
          this.testExpire = true;
        }
        else {
          if (this.viewport_width < 576) {
            this.toggle_pallete();
          }
          this.testSubmitted = true;
        }
      })
    }
    if(sessionStorage.getItem('is_test_complete') != '1'){
      this.saveTestStatus(1);
      sessionStorage.setItem('is_test_complete', '1');
    } 
    

  }

  markForReview(modal = '') {
    /* this.finalSelectedFile ={};
    this.uploadFileResponse = {}; */

    if(this.is_neet_test == 1){
      let subject_id = this.activeQuesData.subject_id;
      let section_id = this.activeQuesData.section_id;
      let section_data = this.subjects.find(data => (data.subject_id == subject_id+","+section_id));
      let attempt_questions = section_data.attempt_questions;
      let question_ids = this.ques_data.filter(data => (data.subject_id == subject_id && data.section_id == section_id))
      question_ids = question_ids.map(data => data.ques_id);
      let count = question_ids.reduce((previousValue, currentValue) => (this.questionStatus[currentValue].status_number==4||this.questionStatus[currentValue].status_number==5)?previousValue+1:previousValue, 0)
      if(count == attempt_questions){
        this.displayAlertPopup = true;
        this.displayAlertPopupMessage = 'Maximum number of attemaptable questions for this section is '+attempt_questions+'. To attemp this question please unanswer other questions from this section or move to next section.';
        /* alert('All Questions are Mandatory');  */
        return false;
      }
    }


    if (this.questionStatus[this.activeQuesData.ques_id].status_number == 4) {
      this.questionStatus[this.activeQuesData.ques_id].status_number = 5;
      this.questionStatus[this.activeQuesData.ques_id].status_class = 'nta-legend-answered-review';
    }
    else {
      this.questionStatus[this.activeQuesData.ques_id].status_number = 3;
      this.questionStatus[this.activeQuesData.ques_id].status_class = 'nta-legend-not-answered-review';
    }

    let update_ques_id = this.activeQuesData.ques_id;
    let update_ques_type_id = this.activeQuesData.ques_type_id;
    let check = this.updateAnswerStatus(update_ques_id, update_ques_type_id);
    if (!check) {
      return false;
    }
    let prev_ques_id, next_ques_id;
    this.passageFullActive = false;
    /* this.activeQuesNo += 1;
    if (this.ques_data[this.activeQuesNo - 1] == undefined) {
      this.activeQuesNo -= 1;
      prev_ques_id = this.activeQuesData.ques_id;
      next_ques_id = this.activeQuesData.ques_id;
    }
    else {
      prev_ques_id = this.activeQuesData.ques_id;
      this.activeQuesData = this.ques_data[this.activeQuesNo - 1];
      next_ques_id = this.activeQuesData.ques_id;
    } */

    this.activeQuesNo = this.activeQuesData.questionNo + 1;
    if (this.ques_data[this.activeQuesNo] == undefined) {
      this.activeQuesNo -= 1;
      prev_ques_id = this.activeQuesData.ques_id;
      next_ques_id = this.activeQuesData.ques_id;
    }
    else {
      prev_ques_id = this.activeQuesData.ques_id;
      this.activeQuesData = this.ques_data[this.activeQuesNo];
      next_ques_id = this.activeQuesData.ques_id;
    }
    
    this.optimizeMatchMatrix();
    this.changeQueTimer(prev_ques_id, next_ques_id);
    this.calculateLegendStatus();
    this.setSubject(this.activeQuesData);

    if(modal) {
      this.common.openVerticallyCentered(modal)
    }
  }

  updateAnswerStatus(ques_id, ques_type_id) {
    let answer = '-1';
    let status_number = 2;
    let answerClass = 'nta-legend-not-answered';
    switch (ques_type_id) {
      case 1: //Single Choice
        if (this.OptionsFormatComponent.answers[ques_id] >= 1 && this.OptionsFormatComponent.answers[ques_id] <= 10) {
          answer = this.OptionsFormatComponent.answers[ques_id];
          answer = String.fromCharCode(96 + parseInt(answer));
          status_number = 4;
          answerClass = 'nta-legend-answered';
        }
        break;
      case 2: //Subjective
        /* if (this.OptionsFormatComponent.answers[ques_id] != undefined || this.checkIfFileIsUploaded()) { */
          if (this.OptionsFormatComponent.answers[ques_id] != undefined || this.checkIfFilesAreUploaded(ques_id)) {
          if (this.OptionsFormatComponent.answers[ques_id] != undefined && this.OptionsFormatComponent.answers[ques_id] != '') {
            answer = btoa(this.OptionsFormatComponent.answers[ques_id]);
            status_number = 4;
            answerClass = 'nta-legend-answered';
          } else {
            if(this.selectedFileName){
              answer = btoa('A File has been uploaded');
              status_number = 4;
              answerClass = 'nta-legend-answered';
            }
          }
        } 
        break;
      case 3: //Multiple Choice
        let ans = this.OptionsFormatComponent.matrixAnswer[ques_id];
        answer = '';
        for (const key in ans) {
          if (ans.hasOwnProperty(key)) {
            if (ans[key]) {
              let op = String.fromCharCode(96 + parseInt(key));
              answer = answer + op + ',';
            }
          }
        }

        let len = answer.length;
        if (len > 0) {
          if (answer[len - 1] == ',') {
            answer = answer.slice(0, -1);
          }
          status_number = 4;
          answerClass = 'nta-legend-answered';
        }
        else {
          answer = '-1';
        }
        break;
      case 5: //True - False
        if (this.OptionsFormatComponent.answers[ques_id] == 1) {
          answer = 'a';
          status_number = 4;
          answerClass = 'nta-legend-answered';
        }
        else if (this.OptionsFormatComponent.answers[ques_id] == 0) {
          answer = 'b';
          status_number = 4;
          answerClass = 'nta-legend-answered';
        }
        break;
      case 6: //Match Matrix
        ans = this.OptionsFormatComponent.matrixAnswer[ques_id];
        answer = '';
        let temp = [];
        for (const key in ans) {
          if (ans.hasOwnProperty(key)) {
            if (ans[key]) {
              let arr = key.split('_');
              //answer = answer+arr[0]+'='+arr[1]+',';
              if (temp[arr[0]] == undefined) {
                temp[arr[0]] = '';
              }
              temp[arr[0]] += String.fromCharCode(96 + parseInt(arr[1])) + '|';
            }
          }
        }

        for (const key in temp) {
          if (temp.hasOwnProperty(key)) {
            const element = temp[key];
            let op: number = parseInt(key);
            if (this.student_data.user_type == 99) {
              answer = answer + (op) + '=' + (temp[key].slice(0, -1)) + ',';
            }
            else {
              answer = answer + (op - 1) + '=' + (temp[key].slice(0, -1)) + ',';
            }
          }
        }

        len = answer.length;
        if (len > 0) {
          if (answer[len - 1] == ',') {
            answer = answer.slice(0, -1);
          }
          status_number = 4;
          answerClass = 'nta-legend-answered';
        }
        else {
          answer = '-1';
        }
        break;
      case 7: //Match Following
        ans = this.OptionsFormatComponent.matrixAnswer[ques_id];
        answer = '';
        temp = [];
        for (const key in ans) {
          if (ans.hasOwnProperty(key)) {
            if (ans[key]) {
              let arr = key.split('_');
              //answer = answer+arr[0]+'='+arr[1]+',';
              if (temp[arr[0]] == undefined) {
                temp[arr[0]] = '';
              }
              temp[arr[0]] += String.fromCharCode(96 + parseInt(arr[1])) + '|';
            }
          }
        }

        for (const key in temp) {
          if (temp.hasOwnProperty(key)) {
            let op: number = parseInt(key);
            if (this.student_data.user_type == 99) {
              answer = answer + (op) + '=' + (temp[key].slice(0, -1)) + ',';
            }
            else {
              answer = answer + (op - 1) + '=' + (temp[key].slice(0, -1)) + ',';
            }
          }
        }

        len = answer.length;
        if (len > 0) {
          if (answer[len - 1] == ',') {
            answer = answer.slice(0, -1);
          }
          status_number = 4;
          answerClass = 'nta-legend-answered';
        }
        else {
          answer = '-1';
        }
        break;
    }

    if ((this.testSettings.is_all_mandatory_questions > 0 && this.is_neet_test == 0) && status_number < 4) {
      this.displayAlertPopup = true;
      this.displayAlertPopupMessage = 'All Questions are Mandatory';
      /* alert('All Questions are Mandatory');  */
      return false;
    }
    if (status_number == 2 && (this.questionStatus[ques_id].status_number == 5 || this.questionStatus[ques_id].status_number == 3)) {
      this.questionStatus[ques_id] = { 'status_number': 3, 'answer': answer, 'status_class': 'nta-legend-not-answered-review' };
    }
    else if (status_number == 4 && (this.questionStatus[ques_id].status_number == 5 || this.questionStatus[ques_id].status_number == 3)) {
      this.questionStatus[ques_id] = { 'status_number': 5, 'answer': answer, 'status_class': 'nta-legend-answered-review' };
    }
    else {
      this.questionStatus[ques_id] = { 'status_number': status_number, 'answer': answer, 'status_class': answerClass };
    }
    return true;
  }

  filterQueStatus(queObj) {
    return queObj.filter(function (el) {
      return el != null;
    });
  }

  onResize(event) {
    event.target.innerWidth;
    alert('window is minimized !')

  }



  previousQuestion() {
    let prev_ques_id, next_ques_id;
    if (this.activeQuesData.questionNo == 0 || this.testSettings.is_allow_back_button !== 1)
      return false;
    this.activeQuesNo -= 1;
    this.passageFullActive = false;
    prev_ques_id = this.activeQuesData.ques_id;
    // this.activeQuesNo = this.activeQuesData.questionNo - 1;
    this.activeQuesData = this.ques_data[this.activeQuesData.questionNo - 1];
    this.quesAttemptTime = this.ques_data[this.activeQuesNo].attempt_duration;
    if (this.activeQuesData.attempt_duration >= 60) {
      this.quesAttemptTime = (this.activeQuesData.attempt_duration / 60) + ' h ' + (this.activeQuesData.attempt_duration % 60) + ' m'
    }
    else {
      this.quesAttemptTime = this.activeQuesData.attempt_duration + ' mins';
    }

    var test = document.getElementById("question-number-"+this.activeQuesNo);
      
        if(test != null) {
          test.scrollIntoView();
        }

    next_ques_id = this.activeQuesData.ques_id;
    this.optimizeMatchMatrix();
    this.changeQueTimer(prev_ques_id, next_ques_id);
    this.calculateLegendStatus();
    this.setSubject(this.activeQuesData)
  }

  switch_section = function (event: any) {

    let childs = event.target.parentElement.getElementsByTagName('a');
    this.common.switch_active(this.renderer, childs, "active", event.target);
  }



  changeScreen(event) {
  //  console.log(event);
    this.methodToBeInvoked = '';
    var el = document.documentElement;
    this.viewFullScreen = !this.viewFullScreen; // on 1st click it will be false
    if (!this.viewFullScreen) {
      this.methodToBeInvoked = el['requestFullscreen'] || el['msRequestFullscreen'] || el['mozRequestFullScreen'];
      if (this.methodToBeInvoked) {
        this.methodToBeInvoked.call(el);
        this.viewScreen = 'View Small Screen';
      }
    }
    else {
      this.methodToBeInvoked = document['exitFullscreen'] || document['webkitExitFullscreen'] || document['mozCancelFullScreen'] || document['webkitExitFullscreen'];
      if (this.methodToBeInvoked) {
        this.methodToBeInvoked.call(document);
        this.viewScreen = 'View Full Screen';
      }
    }

  }


  toggle_pallete = function () {
    if (this.palleteToggle) {
      this.rightPallete = 'active';
      this.leftPallete = 'hide';
      this.palleteCaret = 'left';
    }
    else {
      this.rightPallete = '';
      this.leftPallete = '';
      this.palleteCaret = 'right';
    }
    this.palleteToggle = (this.palleteToggle) ? false : true;
  }

  resizePassage = () => {
    let el = document.getElementById('passageWrapper');
    this.isPassageOverflow = (el.clientHeight > 100) ? true : false;
  }

  togglePassage(action) {
    if (action == 1) {
      this.passageFullActive = true;
    }
    else {
      this.passageFullActive = false;
    }
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
  }

  closeWindow() {
    window.close();
  }
}

