import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { isBoolean } from 'util';
import LZString from '../../lz-string.js'

@Component({
  selector: 'guest-question-wise-report',
  templateUrl: './guest-question-report.component.html',
  styleUrls: ['./guest-question-report.scss']
})
export class GuestQuestionWiseReportComponent implements OnInit {
  guestQuesWiseReport: any;
  studentReport: any = [];
  studentAnswerArray: any = [];
  test_data: any;
  selectedlanguageID: any;
  selectedLangQuesData: any = [];
  optionArray: any;
  optionArrayMatchMatrix: any = [];
  languageData: any =[];
  testStatus: any;
  @Output() studentReportData: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.test_data = JSON.parse(sessionStorage.getItem('test_data'));
    var data = JSON.parse(sessionStorage.getItem('language-wise-questions'));
    this.languageData = data;
    if (this.test_data.is_multi_lingual == 1) {
      this.selectedlanguageID = JSON.parse(sessionStorage.getItem('language_id'));
     
      var temp = this.languageData.find(obj => obj.id == this.selectedlanguageID);
      console.log(temp);
      this.selectedLangQuesData = temp.ques_data;
      console.log('ques data in sel lang', this.selectedLangQuesData);
    }
    this.showQuestionWiseReport();
  }

  showQuestionWiseReport() {
    this.studentAnswerArray = JSON.parse(sessionStorage.getItem('user-answers'));

    console.log('Correct ans array:', this.studentAnswerArray);
    let saved = JSON.parse(sessionStorage.getItem('is_data_compress'));
    if(saved == 1) {
      this.guestQuesWiseReport = JSON.parse(LZString.decompress(sessionStorage.getItem('ques_data')));
    } else {
      this.guestQuesWiseReport = JSON.parse(sessionStorage.getItem('ques_data'));
    }
    /* this.guestQuesWiseReport = JSON.parse(sessionStorage.getItem('ques_data')); */
    this.testStatus = JSON.parse(sessionStorage.getItem('test_status'));
    this.studentReport = [];
    console.log(this.guestQuesWiseReport);
    this.guestQuesWiseReport.forEach(element => {
      let obj = {
        questionId: element.ques_id,
        question: this.test_data.is_multi_lingual != 1 ? element.question : '',
        answer: element.answer,
        correctAnswer:element.correct_answer,
        isCorrect : false, 
        questionOptions: this.test_data.is_multi_lingual != 1 ? element.options : '',
        questionFeedback: element.ques_feedback !== '' ? this.decodeOption(element.ques_feedback) : '',
        questionType: element.ques_type_id,
        positiveMarks: element.positive_marks,
        negativeMarks: element.negative_marks,
        notAttemptedMarks: element.not_attempt_marks,
        subjectId: element.subject_id,
        attemptedTime: '',
      };
      this.studentReport.push(obj);
      MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
      MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    });
    this.mapCorrectAnswer();
     this.studentAnswerArray.forEach(obj => {
        var temp ='';
        temp = this.studentReport.find(ob=> ob.questionId == obj.ques_id);
        
      }) 
    if (this.test_data.is_multi_lingual == 1) {
      this.selectedLangQuesData.forEach(obj => {
        var temp;
        temp = this.studentReport.find(ob => ob.questionId == obj.ques_id);
        temp.question = this.decodeOption(obj.question);
        temp.questionOptions = temp.questionType != 2 ? this.getOptions(obj) : [];
      });
    }
    this.checkIfAnswerIsCorrect(this.studentReport);
    console.log(this.studentReport);
   sessionStorage.setItem('student-answer-report',JSON.stringify(this.studentReport));
  }

  mapCorrectAnswer() {
    this.studentAnswerArray.forEach((key, value) => {
      this.studentReport[value].studentAnswer = '';
      this.studentReport[value].studentAnswer = this.studentAnswerArray[value];
    })
    console.log('Array after addimg elmts:', this.studentReport);
   // sessionStorage.setItem('student-answer-report', JSON.stringify(this.studentReport));
    this.studentReportData.emit(this.studentReport);
  }
  

  checkIfAnswerIsCorrect(studentData){
    var getSubjectiveQuestions = studentData.filter(ob => ob.questionType == 2)
    getSubjectiveQuestions.forEach((k,v)=>{
      k.studentAnswer = this.testStatus.subjective_answer[v] != '-1' ? decodeURIComponent(escape(window.atob(this.testStatus.subjective_answer[v]))) : 'NA';
    })
    
    studentData.forEach((key,val)=>{
   
    if(key.studentAnswer == -1 || key.studentAnswer == 'NA' || key.correctAnswer != key.studentAnswer){
      key.isCorrect = false;
    }
    else {
      key.isCorrect =  true;
    }
  })
    
  }

  getCharacter(index) {
    return String.fromCharCode(index);
  }

  getOptions(quesData) {
    this.optionArray = [];
    this.optionArrayMatchMatrix = [];

    if (quesData.ques_type_id == 6 || quesData.ques_type_id == 7) {
      this.optionArrayMatchMatrix = quesData.options;
      return this.optionArrayMatchMatrix;
    }
    else {
      quesData.options.forEach((obj,index) => {
        let obj1 = {
          value: obj.answer_option,
          is_correct:obj.is_correct
        }
        this.optionArray.push(obj1)
/*         this.optionArray.push(obj.answer_option);
 */      });
      return this.optionArray;
    }
  }
  // for decoding choices
  decodeOption(opt) {
    return decodeURIComponent(escape(atob(opt)));
  }

  trueFalseChoice(option) {
    if (option == 1) {
      return 'True';
    }
    else {
      return 'False';
    }
  }


}