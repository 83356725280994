import { Injectable, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { MomentModule } from 'ngx-moment';
import * as globals from './../config/globals';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  globals: any = globals.globals;
  auth_token: any;
  institute_data: any;
  student_data: any;
  test_data: any;
  attempt_data: any;
  test_status: string;
  labelColor: string;
  test_duration: any;
  is_preview: boolean = false;
  show_live_class_btn: boolean = false;
  live_class_url:string = '';

  loading: boolean = true;
  authorised: boolean = false;
  showError: boolean = false;
  show_proceed: boolean = false;
  apiError: any;
  test_status_message: string;
  test_start_date: any;
  test_end_date: any;
  sessionKey: any;
  test_type: string;
  sessionLoading: boolean = false;
  element: HTMLLinkElement;
  languagesList: any = [];
  imgUrl: any;

  days:any;
  hours: any;
  minutes: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public api: ApiService
  ) {
    this.route.params.subscribe(params => {
      this.auth_token = params.token;
      sessionStorage.setItem('auth_token', this.auth_token);
    });
  }

  ngOnInit() {
    console.log('global object',this.globals.client_logo);
    this.imgUrl =  this.globals.client_logo; /* +'/'+ Math.random(); */

    let sso_login_details = sessionStorage.getItem("sso_login_details");
    let login_token = sessionStorage.getItem("login_token");
    let component_details = sessionStorage.getItem("component_details");

    if(sessionStorage.getItem('via_sso') == '1') {
      sessionStorage.clear();
      sessionStorage.setItem('sso_login_details', sso_login_details);
      sessionStorage.setItem('login_token', login_token);
      sessionStorage.setItem('component_details', component_details);
    } else {
      sessionStorage.clear();
    }
    sessionStorage.setItem('via_sso', '0');

    this.auth_request();
  }

  auth_request() {
    this.loading = true;
    this.api.auth_request(this.auth_token).subscribe(resp => {
      if (resp['validate'] == true) {
        let data = resp['data'];
        this.languagesList = data.test_languages;
        console.log('lang list:', this.languagesList);
        sessionStorage.setItem('multi-languages', JSON.stringify(this.languagesList));

        this.institute_data = data.institute_data;
        if(this.institute_data.logoPath != null && this.institute_data.logoPath != '') {
          globals.globals.client_logo = this.institute_data.logoPath;
        }
        globals.globals.client_name = this.institute_data.account_name;
        if(this.institute_data.favIconPath != null && this.institute_data.favIconPath != '') {
          globals.globals.favIcon = this.institute_data.favIconPath;
        }
        sessionStorage.setItem('institute_data', JSON.stringify(data.institute_data));
        this.attempt_data = sessionStorage.setItem('attempt_data', JSON.stringify(data.attempt_data));

        //var element = new HTMLAnchorElement;
        this.element = document.createElement('link');
        //let link = (<HTMLElement>document.querySelector("link[rel*='icon']"));
        this.element.type = 'image/x-icon';
        this.element.rel = 'icon';
        this.element.href = 'http://www.stackoverflow.com/favicon.ico';
        document.getElementsByTagName('head')[0].appendChild(this.element);

        this.student_data = data.student_data;
        globals.globals.student_data = data.student_data;
        sessionStorage.setItem('student_data', JSON.stringify(data.student_data));

        if (this.student_data.is_preview != undefined) {
          this.is_preview = (parseInt(this.student_data.is_preview)) ? true : false
        }

        this.test_data = data.test_data;
        sessionStorage.setItem('test_data', JSON.stringify(data.test_data));
        console.log('Test data :', this.test_data);

        this.authorised = data.authorised;
        sessionStorage.setItem('authorised', data.authorised);
        sessionStorage.setItem('sessionKey', data.session);
        this.sessionKey = data.session;

        this.test_status = data.test_status;

        var test_start_date = new Date(data.test_data.test_start_timestamp * 1000);
        var test_end_date = new Date(data.test_data.test_end_timestamp * 1000);
        this.test_start_date = this.formatDate(test_start_date);
        this.test_end_date = this.formatDate(test_end_date);

        if(this.test_data.procturing_link != null && this.test_data.procturing_link != '') {
          this.show_live_class_btn = true;
          this.live_class_url = this.test_data.procturing_link;
        }

        switch (this.test_data.test_type_id) {
          case 1:
            this.test_type = 'Mock Test';
            this.days = Math.floor(parseInt(this.test_data.duration)/1440);
             this.test_duration = Math.floor(parseInt(this.test_data.duration)/1440) +'d '+ Math.floor((parseInt(this.test_data.duration)-(this.days * 1440))/60)+'h '+
             Math.round(parseInt(this.test_data.duration) % 60) + 'm';   
            break;
          case 2:
           this.test_type = 'Practice Test';
          // this.test_duration = this.test_data.duration == 0 ? 'No Restriction' : this.test_data.duration + " mins";
           if(this.test_data.duration == 0 || this.test_data.duration == ''){
             this.test_duration = 'No Restriction';
           }
           else {
             this.days = Math.floor(parseInt(this.test_data.duration)/1440);
             this.test_duration = Math.floor(parseInt(this.test_data.duration)/1440) +'d '+ Math.floor((parseInt(this.test_data.duration)-(this.days * 1440))/60)+'h '+
             Math.round(parseInt(this.test_data.duration) % 60) + 'm';
           } 
            this.test_start_date = 0;
            this.test_end_date = 0;
            console.log('date formated:',this.test_duration)
            break;
          case 3:
            this.test_type = 'Classroom Exam';
           // this.test_duration = this.test_data.test_duration + " mins";
             this.days = Math.floor(parseInt(this.test_data.duration)/1440);
             this.test_duration = Math.floor(parseInt(this.test_data.duration)/1440) +'d '+ Math.floor((parseInt(this.test_data.duration)-(this.days * 1440))/60)+'h '+
             Math.round(parseInt(this.test_data.duration) % 60) + 'm';   
          case 4:
            this.test_type = 'Online Exam';
           // this.test_duration = this.test_data.duration + " mins";
             this.days = Math.floor(parseInt(this.test_data.duration)/1440);
             this.test_duration = Math.floor(parseInt(this.test_data.duration)/1440) +'d '+ Math.floor((parseInt(this.test_data.duration)-(this.days * 1440))/60)+'h '+
             Math.round(parseInt(this.test_data.duration) % 60) + 'm';   
            break;
        }
        switch (this.test_status) {
          case 'ACTIVE':
            this.labelColor = 'label-green';
            this.test_status_message = '';
            this.show_proceed = true;
            break;
          case 'UPCOMING':
            this.labelColor = 'label-blue';
            this.test_status_message = 'This is an upcoming test';
            this.show_proceed = true;
            break;
          case 'EXPIRED':
            this.labelColor = 'label-red';
            this.test_status_message = 'This test is already expired!';
            break;

          case 'EXHAUSTED':
            this.labelColor = 'label-red';
            this.test_status_message = this.test_data.test_type_id != 2 ? 'You have already attempted this test.' : 'You have consumed the attempt limit for this test.';
            break;

          case 'QUOTA_EXHAUSTED':
            this.labelColor = 'label-red';
            this.test_status_message = 'Test limit quota has been exhausted, Please contact institute for more details.';
            break;

          case 'INVALID':
          case 'DISABLED':
          case 'NOT_PUBLISHED':
            break;
        }

        this.loading = false;
      }
      else {

      }
    },
      error => {
        this.apiError = error;
        this.loading = false;
        this.showError = true;
      });
  }

  formatDate(date) {
    var monthNames = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "June", "July",
      "Aug", "Sep", "Oct",
      "Nov", "Dec"
    ];

    return date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear() + " "
      + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
  }

  show_instructions() {
    var el = document.body;
    let methodToBeInvoked = el.requestFullscreen  || el['mozRequestFullscreen'] || el['msRequestFullscreen']
    if(methodToBeInvoked){
      methodToBeInvoked.call(el);
      
    } 
    this.router.navigate(['/instructions'], { replaceUrl: true })
  }

  changeSession() {
    this.sessionLoading = true;
    this.api.changeSession(this.student_data.userid).subscribe(res => {
      this.sessionLoading = false;
      if (res['validate']) {
        let data = res['data'];
        sessionStorage.setItem('sessionKey', data.session);
        this.sessionKey = data.session;
      }
      else {

      }
    },
      error => {
        this.apiError = error;
        this.loading = false;
        this.showError = true;
      });
  }

}
