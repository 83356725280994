import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../api.service';
import { MomentModule } from 'ngx-moment';

import * as globals from './../config/globals';
import { globalAgent } from 'https';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit {
  globals: any = globals.globals;

  language: string = 'English';
  default_lang: number = 1;
  accept_terms: boolean = false;
  test_data: any;
  student_data: any;
  attempt_data: any;
  serverTimeStamp: any;
  testEnabled: boolean = false;
  interval: any;
  leftTimeToStart: any;
  formattedLeftTimeToStart: any;
  leftDays: any;
  leftHours: any;
  leftMins: any;
  leftSecs: any;
  startingTest: boolean = false;
  startingBtnText: string = 'Start Test';
  loading: boolean = true;
  multiLanguageslist: any;
  selectedLanguage: any;
  languageJSON: any;

  constructor(private router: Router, public api: ApiService, public moment: MomentModule, private location: Location) {

  }

  ngOnInit() {
    //console.log('lang inside instruc:', JSON.parse(sessionStorage.getItem('multi-languages')));

    if (sessionStorage.getItem('test_data') == undefined || sessionStorage.getItem('student_data') == undefined) {
      this.router.navigate(['/'])
    }
    else {
      this.test_data = JSON.parse(sessionStorage.getItem('test_data'));
      if (this.test_data.is_multi_lingual == 1) {
        this.multiLanguageslist = JSON.parse(sessionStorage.getItem('multi-languages'));
        if(this.multiLanguageslist.length){
        this.selectedLanguage = this.multiLanguageslist[0].id;
        this.changeByLanguage(this.selectedLanguage);
        }
      }
      this.student_data = JSON.parse(sessionStorage.getItem('student_data'));
     // console.log('student data is:', this.student_data);
      this.attempt_data = JSON.parse(sessionStorage.getItem('attempt_data'));
     // console.log('Attempt data is:', this.attempt_data);
      this.languageJSON = [
        {
          'id': 1,
          'title': 'English'
        },
        {
          'id': 2,
          'title': 'Hindi'
        },
        {
          'id': 3,
          'title': 'Kannada'
        }
      ]
      // this.language = 'English';
     // console.log('languages list:', this.languageJSON);
      if (this.student_data.userid !== 1234567890) {
        if (this.test_data.test_type_id == 2) {
          if (this.attempt_data.attempt_data == null && this.attempt_data.attempt_count > 0) {
            this.startingBtnText = 'Restart Test';
          }
          else if (this.attempt_data.attempt_data !== null) {
            this.startingBtnText = 'Resume Test';
          }
          else {
            this.startingBtnText = 'Start Test'
          }
        }
        else {
          if (this.attempt_data.attempt_data == null) {
            this.startingBtnText = 'Start Test';
          }
          else {
            this.startingBtnText = 'Resume Test'
          }
        }

      }
      else {
        this.startingBtnText = 'Start Test';
      }
      this.initTimer();
    }
  }

  changeByLanguage(lang) {
  //  console.log('on language change:', lang);
    if (isNaN(lang)) {
      var temp = this.multiLanguageslist.find(ob => ob.title == lang);
    //  console.log(temp);
      sessionStorage.setItem('selected-language', JSON.stringify(temp.id));
    }
    else {
      sessionStorage.setItem('selected-language', JSON.stringify(lang));
    }
  }

  initTimer() {
    this.api.server_time().subscribe(res => {
      this.loading = false;
      this.serverTimeStamp = res['timestamp'];
     // console.log(JSON.stringify(this.serverTimeStamp));
      sessionStorage.setItem('exam-date', this.serverTimeStamp);
    },
      errors => {
        this.loading = false;

      });

    this.interval = setInterval(() => {
      this.serverTimeStamp += 1;
      this.leftTimeToStart = this.test_data.test_start_timestamp - this.serverTimeStamp;
      this.formattedLeftTimeToStart = Math.floor(this.leftTimeToStart / 3600)
        + ' : ' + ("0" + (Math.floor(this.leftTimeToStart / 60) % 60)).slice(-2)
        + ' : ' + ("0" + this.leftTimeToStart % 60).slice(-2);

      this.leftHours = Math.floor(this.leftTimeToStart / 3600);
    //  console.log('left hours:', this.leftHours);
      this.leftDays = Math.floor(this.leftHours / 24);
    //  console.log('left days:', this.leftDays)
      this.leftMins = ("0" + (Math.floor(this.leftTimeToStart / 60) % 60)).slice(-2);
      this.leftSecs = ("0" + this.leftTimeToStart % 60).slice(-2);
      if (this.leftTimeToStart <= 0) {
        this.testEnabled = true;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  begin_test() {
    this.startingTest = true;
    this.startingBtnText = '<i class="fas fa-circle-notch fa-spin"></i>&nbsp; Loading...';
    this.router.navigate(['/panel'], { replaceUrl: true })
    this.startingTest = false;
  }

}
