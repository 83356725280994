import { Component, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { ApiService } from '../api.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { QuestionWiseReportComponent } from './question-wise-report/question-wise-report.component';
import { SubjectWiseReportComponent } from './subject-wise-report/subject-wise-report.component';
import { Chart } from 'chart.js';
import * as globals from './../config/globals';




@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
})
export class ResultComponent implements OnInit {

  @ViewChild(SubjectWiseReportComponent) subjectWise: SubjectWiseReportComponent;
  @ViewChild(QuestionWiseReportComponent) questionWiseObj: QuestionWiseReportComponent;

  @ViewChild('chartCanvas') canvas: any;
  @ViewChild('marksChart') marks: any;

 

  result_token: any;
  loading: boolean = true;
  showError: boolean = false;
  tab_question_wise_analysis: any;
  tab_subject_wise_analysis: any;
  tab_summary: any;
  question_wise_distribution_report: any;
  total_question: any;
  is_neet_test: any;
  subject_wise_distribution_report: any= [];
  studentReport: any = [];
  questionReport: any = [];
  chartType: any;
  innerWidth:any;
  percentage: any;
  overlay: any = {
    mainMessage: 'Error Unknown',
    helpMessage: 'Oops! Something went wrong. Please try again later.'
  }
  student_data: any;
  test_data: any = [];
  test_start_date: string;
  test_end_date: string;
  summary: any = [];
  string_time_taken: string;
  displayChart: boolean = false;
  canvas2d: any;
  exam_date: any;
  exam_start_time: any;
  exam_end_time: any;
  loadQuestionWiseComponent: boolean = false;
  loadSubjectWiseComponent: boolean = true;
  loadTestSummary: boolean = false;
  globals: any = globals.globals;
  colorArray: any = [];

  //for guest user
  questions: any;
  test: any;
  user_answers: any;

  ans_pool: any = [];
  ans_stats: any = {
    correct_ans: 0,
    incorrect_ans: 0,
    not_attempted_ans: 0,
    marks_obtained: 0,
    correct_marks: 0,
    incorrect_ans_marks: 0,
    not_attempted_marks: 0,
    total_time_taken: 0,
    string_time_taken: ''
  }





  constructor(public api: ApiService, public router: Router, public elemRef: ElementRef) {
    this.student_data = JSON.parse(sessionStorage.getItem('student_data'));
    this.test_data = JSON.parse(sessionStorage.getItem('test_data'));

    this.test_start_date = moment(this.test_data.test_start_timestamp).format('DD MMM YYYY LT');
    this.test_end_date = moment(this.test_data.test_end_timestamp).format('DD MMM YYYY LT');


  }

  @HostListener('document:contextmenu', ['$event'])
  onDocumentRightClick(event) {
    event.preventDefault();
  }

  @HostListener('document:backbutton', ['$event'])
  onPopState(event) {
    event.preventDefault();
    console.log(event);
  }

  ngOnInit() {
   let testComplete = parseInt(sessionStorage.getItem('is_test_complete'));
   this.student_data.name = this.student_data.name.split(' ')[0];
   this.innerWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
   console.log('width of sys is:',this.innerWidth);
    if (testComplete == 1) {
      this.api.fetch_result().subscribe(resp => {
        console.log(resp);
        this.summary = resp['data'].test_report;
        this.calcPercent();
        console.log('Test Report:', this.summary);
        this.question_wise_distribution_report = resp['data'].question_wise_distribution;      
        this.subject_wise_distribution_report = resp['data'].subject_wise_distribution;
     
        this.summary.total_attempted_time = Math.floor(this.summary.total_attempted_time);
        this.is_neet_test = resp['data'].topper_report.is_neet_test;
        this.total_question = resp['data'].topper_report.total_question;
        this.string_time_taken = Math.floor(this.summary.total_attempted_time / 3600)
          + ' : ' + ("0" + (Math.floor(this.summary.total_attempted_time / 60) % 60)).slice(-2)
          + ' : ' + ("0" + this.summary.total_attempted_time % 60).slice(-2);
        var obj: any = sessionStorage.getItem('exam-date');
        var date = new Date(obj * 1000);
        this.exam_date = this.pad(date.getDate()) + '-' + date.toLocaleString('default', { month: 'short' }) + '-' + date.getFullYear();
        this.exam_start_time = this.pad(date.getHours()) + ' : ' + this.pad(date.getMinutes()) + ' : ' + this.pad(date.getSeconds());
        var end_time: any = sessionStorage.getItem('test_end_time');
        var end_dt = new Date(end_time * 1000);
        this.exam_end_time = this.pad(end_dt.getHours()) + ' : ' + this.pad(end_dt.getMinutes()) + ' : ' + this.pad(end_dt.getSeconds());
        this.selectChart(2);
        this.loading = false;
      },
        errors => {
          console.log(errors);
          this.loading = false;
          this.showError = true;
          this.overlay = {
            mainMessage: 'Error ' + errors.status + '! ' + errors.statusText,
            helpMessage: errors.error.errors.message
          }
        });
    }

    else {
      this.loading = false;
      this.showError = true;
      this.overlay = {
        mainMessage: 'Invalid Request',
        helpMessage: 'You are requesting the page with invalid data'
      };
    }




  }
  // for labeling index in alphabets
  getCharacter(index) {
    return String.fromCharCode(index);
  }

  pad(num) {
    return ("0" + num).slice(-2);
  }

  decodeOption(opt) {
    return decodeURIComponent(escape(atob(opt)));
  }

  /* calcPercent(score, marks) {
    return (((score / marks)) * 100).toFixed(2);
  } */
  calcPercent(){
    this.percentage = '';
    this.percentage = (((this.summary.total_score / this.summary.total_marks)) * 100).toFixed(2);
    console.log('percentage obtained is:',this.percentage)
   /*  return (((this.summary.total_score / this.summary.total_marks)) * 100).toFixed(2); */
  }

  renderQuestionReportChart() {
    this.questionReport = [];
    this.questionReport.push(this.summary['left_ques_count']);
    this.questionReport.push(this.summary['right_ques_count']);
    this.questionReport.push(this.summary['wrong_ques_count']);

    let ctx = this.canvas.nativeElement.getContext('2d');
    let chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Unattempted', 'Correct Answer', 'Incorrect Answer',],
        datasets: [{
          label: '# of Votes',
          data: this.questionReport,
          backgroundColor: [
            '#FFFF00',
            '#00FF00',
            '#FF0000',

          ],
          borderWidth: 0
        }]
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: 'Answer Based Analysis'
        },
        legend: {
          display: false,
          position: 'bottom',

        }
      }
    });
  }

  renderMarksReportChart() {
    var subjectNames = this.subject_wise_distribution_report.map(obj => {
      return obj.subject_name;
    });
    var score = this.subject_wise_distribution_report.map(ob => {
      return ob.total_score;
    });

    var removeZeros = score.filter(ob => ob !==0);
    console.log(removeZeros);
    var letters = '0123456789ABCDEF';
    for (var i = 0; i < removeZeros.length; i++) {
      var color = '#';
      for (var j = 0; j < 6; j++) {
        color = color + letters[Math.floor(Math.random() * 16)];

      }
      this.colorArray.push(color);
    }

    console.log('Color array is:', this.colorArray);
    if(removeZeros.length){
    let ct = this.marks.nativeElement.getContext('2d');
    let chart = new Chart(ct, {
      type: 'doughnut',
      data: {
        labels: subjectNames,
        datasets: [
          {
            label: "Marks",
            backgroundColor: this.colorArray,
            borderWidth: 0,
            data: score
          }
        ]
      },
      options: {
        responsive: true,
        legend: {
          display: false,
          position: 'bottom'
        },
        title: {
          display: true,
          text: 'Subject Based Analysis'
        },
      }
    });
  }


  }

  selectChart(type) {
    if (type == 0) {
      this.loadSubjectWiseComponent = true;
      this.loadQuestionWiseComponent = false;
      this.loadTestSummary = false;
      this.tab_subject_wise_analysis = 'active';
      this.tab_question_wise_analysis = '';
      this.tab_summary = '';
      this.chartType = 0;
      this.subjectWise.showSubjectWiseReport();
    }
    else if (type == 1) {
      this.loadSubjectWiseComponent = false;
      this.loadQuestionWiseComponent = true;
      this.loadTestSummary = false;
      this.chartType = 1;
      this.tab_subject_wise_analysis = '';
      this.tab_question_wise_analysis = 'active';
      this.tab_summary = '';
      this.questionWiseObj.showQuestionWiseReport();
     
    }
    else {
      this.loadTestSummary = true;
      this.loadQuestionWiseComponent = false;
      this.loadSubjectWiseComponent = false;
      this.chartType = 2;
      this.tab_summary = 'active';
      this.tab_question_wise_analysis = '';
      this.tab_subject_wise_analysis = '';
      this.renderQuestionReportChart();
      this.renderMarksReportChart();
    }
  }




}
