import { Component, OnInit, Input } from '@angular/core';
import LZString from '../../../../lz-string.js'

@Component({
  selector: 'app-options-format',
  templateUrl: './options-format.component.html',
  styleUrls: ['./options-format.component.scss']
})
export class OptionsFormatComponent implements OnInit {

  answers: any = [];
  matrixAnswer: any[][] = [];
  array: any = [];
  split: any = [];
  newarray: any = [];
  test_data: any ;
  multiLanguagesQuesList: any =[];

  @Input()
  activeQuesData: any;

  constructor() {
  }

  ngOnInit() {
  this.test_data = JSON.parse(sessionStorage.getItem('test_data'));
   this.multiLanguagesQuesList = JSON.parse(sessionStorage.getItem('multi-languages'));
  // console.log('multi lang list:',this.multiLanguagesQuesList);
    this.matrixAnswer[this.activeQuesData.ques_id] = [];
   // console.log('active ques in options file', this.activeQuesData);
    this.initAnswerStatus();
    
  }
 

  checkInputType(event){
    if(this.activeQuesData.ans_data_type == 'int' || this.activeQuesData.ans_data_type == 'decimal'){
      if( event.keyCode == 8 || event.keyCode == 46 || (event.keyCode >= 48 && event.keyCode <= 57) ){
       return;// event.default();
      }
      else{
        event.preventDefault();
      }
    }
    else {
      return;
    }
  }

  decodeOption(opt) {
    return decodeURIComponent(escape(window.atob(opt)));
  }

  initAnswerStatus() {
    let data = JSON.parse(sessionStorage.getItem('attempt_data'));
    let saved = JSON.parse(sessionStorage.getItem('is_data_compress'));
    if(saved == 1) {
      var ques_data = JSON.parse(LZString.decompress(sessionStorage.getItem('ques_data')));
    } else {
      var ques_data = JSON.parse(sessionStorage.getItem('ques_data'));
    }

    if (data != undefined) {
      let attempt_data = JSON.parse(data.answer_json);
      let ques_ids = Object.values(attempt_data.questions.split('#'));
      let answers = attempt_data.answers.split('#');
      let sub_answers = attempt_data.subjective_answer;
      ques_data.forEach(ques => {

        let index = ques_ids.indexOf(ques.ques_id.toString());
        let ans = answers[index];
        let ques_type_id = ques.ques_type_id;

        if (ans == '-1') {
          return;
        }

        switch (ques_type_id) {
          case 1: //Single Choice
            if (ans != '-1' && ans >= 'a' && ans <= 'z') {
              ans = ans.charCodeAt();
              ans -= 96;
              this.answers[ques.ques_id] = ans;
            }
            break;
          case 5: //True, False
            if (ans == 'a') {
              this.answers[ques.ques_id] = 1;
            }
            else if (ans == 'b') {
              this.answers[ques.ques_id] = 0;
            }
            else {
              this.answers[ques.ques_id] = '-1';
            }
            break;
          case 2: //Subjective
            let sub_index = ans.split('_')[1];
            if (sub_answers[sub_index] != '-1') {
              this.answers[ques.ques_id] = atob(sub_answers[sub_index]);
            }
            break;
          case 3: //Multiple Choice
            let ans_arr = ans.split(',');
            let op_count = ques.options.length;
            this.matrixAnswer[ques.ques_id] = [];
            for (let i = 0; i < op_count; i++) {
              let char = String.fromCharCode('a'.charCodeAt(0) + i);
              if (ans_arr.indexOf(char) > -1) {
                this.matrixAnswer[ques.ques_id][i + 1] = true;
              }
              else {
                this.matrixAnswer[ques.ques_id][i + 1] = false;
              }
            }
            break;

          case 6: //Match Matrix
            ans_arr = ans.split(',');

            let opt_arr = [];
            ans_arr.forEach(opt => {
              let left_opt = opt.split('=')[0];
              let right_opt = opt.split('=')[1];
              opt_arr[left_opt] = right_opt.split('|');
            });

            this.matrixAnswer[ques.ques_id] = [];
            opt_arr.forEach((value, lopt) => {
              lopt++;
              value.forEach(ropt => {
                ropt = ropt.charCodeAt() - 96;
                this.matrixAnswer[ques.ques_id][lopt + '_' + ropt] = true;
              });
            });
            break;
          case 7: //Match Following
            ans_arr = ans.split(',');
            this.matrixAnswer[ques.ques_id] = [];
            ans_arr.forEach(opt => {
              let left_opt = parseInt(opt.split('=')[0]) + 1;
              let right_opt = opt.split('=')[1].charCodeAt(0) - 96;
              this.matrixAnswer[ques.ques_id][left_opt + '_' + right_opt] = true;
            });
            break;
        }
      });

      /*
      console.log('------ Other Answers ---- ')
      console.log(this.answers)

      console.log(' ----------- Matrix Answer -------- ');
      console.log(this.matrixAnswer)
      */
    }
  }

  getChar(index) {
    return '(' + String.fromCharCode(index) + ')';
  }

  getLeftSerialNo(index) {
    let sr_no;
    switch (index) {
      case 1: sr_no = 'a'; break;
      case 2: sr_no = 'b'; break;
      case 3: sr_no = 'c'; break;
      case 4: sr_no = 'd'; break;
      case 5: sr_no = 'e'; break;
      case 6: sr_no = 'f'; break;
      case 7: sr_no = 'g'; break;
      case 8: sr_no = 'h'; break;
      case 9: sr_no = 'i'; break;
      case 10: sr_no = 'j'; break;
    }

    return '(' + sr_no + ') ';
  }
  limitByWords(allowedLimit) {
    console.log('word limit is:',allowedLimit);
    let words = this.answers[this.activeQuesData.ques_id];
    let wordArr = words.split(' ');
    wordArr = wordArr.filter(obj => obj !== '');

    if(wordArr.length > allowedLimit)
    {
      wordArr.splice(allowedLimit, wordArr.length);
      this.answers[this.activeQuesData.ques_id] = wordArr.join(' ');
    }
  }

  getRightSerialNo(index) {
    return '(' + String.fromCharCode(index) + ')';
  }

  markMultipleAnswer() {
    let answers = document.getElementsByName('mcBoxes[]');
    this.matrixAnswer[this.activeQuesData.ques_id] = [];
    answers.forEach(el => {
      if (el['checked']) {
        this.matrixAnswer[this.activeQuesData.ques_id][el['value']] = true;
      }
      else {
        this.matrixAnswer[this.activeQuesData.ques_id][el['value']] = false;
      }
    });
  }

  markMatrixAnswer() {
    let answers = document.getElementsByName('matrixBoxes[]');
    this.matrixAnswer[this.activeQuesData.ques_id] = [];
    answers.forEach(el => {
      let arr = el['value'].split(':');
      let option = arr[0];
      let answer_option = arr[1];

      if (el['checked']) {
        this.matrixAnswer[this.activeQuesData.ques_id][option + '_' + answer_option] = true;
      }
      else {
        this.matrixAnswer[this.activeQuesData.ques_id][option + '_' + answer_option] = false;
      }
    });
  }

  markMatchAnswer() {
    let op_count = this.activeQuesData.options.length;
    this.matrixAnswer[this.activeQuesData.ques_id] = [];
    for (let i = 1; i <= op_count; i++) {
      let ans = document.getElementsByName('matrixBoxes[' + i + ']');
      ans.forEach(el => {
        let answer_option = el['value'];
        if (el['checked']) {
          this.matrixAnswer[this.activeQuesData.ques_id][i + '_' + answer_option] = true;
        }
        else {
          this.matrixAnswer[this.activeQuesData.ques_id][i + '_' + answer_option] = false;
        }
      });
    }
  }

  is_checked(ques_id, ques_type_id, la, lr = null) {
    if (this.matrixAnswer[ques_id] == undefined)
      return false;

    la++;
    if (ques_type_id == 3) //Multiple Choice
    {
      if (this.matrixAnswer[ques_id][la])
        return true
      else
        return false;
    }
    else if (ques_type_id == 6 || ques_type_id == 7) {
      lr++;
      if (this.matrixAnswer[ques_id][la + '_' + lr])
        return true
      else
        return false;
    }

  }

  updateAnswer() {
    console.log(this.answers);
  }

}
