import { Component, OnInit, Input } from '@angular/core';
import { element } from '@angular/core/src/render3';
import { Options } from 'selenium-webdriver/safari';

@Component({
  selector: 'question-wise-report',
  templateUrl: './question-wise-report.html',
  styleUrls: ['./question-wise-report.scss']
})
export class QuestionWiseReportComponent implements OnInit {

  question_wise_report: any;
  multipleChoiceQuestions: any;
  multipleChoiceAnswers: any;
  selectedLangQuesData: any = [];
  selectedlanguageID: any;
  optionArray: any = [];
  test_data: any;
  optionArrayMatchMatrix: any = [];
  languageData: any = [];

  @Input() questionBasedData: any;
  studentReport: any = [];

  constructor() {

  }
  ngOnInit() {

    this.showQuestionWiseReport();

  }

  showSolution(ques) {
    ques.isExpand = !(ques.isExpand);
  }


  showQuestionWiseReport() {
    this.question_wise_report = this.questionBasedData;
    this.test_data = JSON.parse(sessionStorage.getItem('test_data'));
    if(this.test_data.is_multi_lingual == 1){
    var data = JSON.parse(sessionStorage.getItem('language-wise-questions'));
    
    this.languageData = data;
    if (this.test_data.is_multi_lingual == 1 && this.languageData.length) {
      this.selectedlanguageID = JSON.parse(sessionStorage.getItem('language_id'));
      console.log('selected lang id:', this.selectedlanguageID);

      var temp = this.languageData.find(obj => obj.id == this.selectedlanguageID);
      console.log(temp);
      this.selectedLangQuesData = temp.ques_data;
      console.log('ques data:', this.selectedLangQuesData);
    }
  }
    this.studentReport = [];
    //  this.question_wise_report = JSON.parse(sessionStorage.getItem('question_wise_report'));
    this.question_wise_report.forEach(element => {
      let obj = {
        questionId: element.ques_id,
        question: this.languageData.length == 0 ? decodeURIComponent(escape(atob(element.question_text))) : element.question_text,
        essayDetails: decodeURIComponent(escape(atob(element.essay_details))),
        isCorrect: element.is_correct,
        questionOptions: this.languageData.length == 0 ? element.question_options : [],
        studentAnswer: element.student_answer,
        correctAnswer: element.correct_answer,
        questionFeedback: decodeURIComponent(escape(atob(element.question_feedback))),
        questionType: element.ques_type,
        marksObtained: element.total_score,
        leftChoices: element.question_options.left_choices,
        rightChoices: element.question_options.right_choices,
        /* questionInLanguage: '',
        optionsInLanguage: [],
        leftChoicesInLanguage: [],
        rightChoicesInLanguage: [] */
      };
      this.studentReport.push(obj);
      MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
      MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    });

    if (this.test_data.is_multi_lingual == 1) {
      this.selectedLangQuesData.forEach(obj => {
        var temp;
        temp = this.studentReport.find(ob => ob.questionId == obj.ques_id);
        temp.question = this.getQuestion(obj.question);
        temp.questionFeedback = this.getQuestion(obj.ques_feedback);
        temp.questionOptions = obj.ques_type_id != 2 && obj.ques_type_id != 4  ? this.getOptions(obj) :[];
        /*  temp.leftChoices = (temp.questionType == 6 || temp.questionType == 7) ? obj.optio:'';
         temp.rightChoices = (temp.questionType == 6 || temp.questionType == 7)? :''; */
      });
    }

    console.log('ques data after lang chnage:', this.studentReport);
  }

  getQuestion(ques) {
    return decodeURIComponent(escape(atob(ques)));
  }

  getOptions(quesData) {
    this.optionArray = [];
    this.optionArrayMatchMatrix = [];
   
      if (quesData.ques_type_id == 6 || quesData.ques_type_id == 7) {
        this.optionArrayMatchMatrix = quesData.options;
        return this.optionArrayMatchMatrix;
      }
      else {
        quesData.options.forEach(obj => {
          this.optionArray.push(obj.answer_option);
        });
        return this.optionArray;
      }
    
  }


  // for spliting correct answers in case of multiple choice question(questionType ==3 )

  getCorrectAnswer(question, index) {
    this.multipleChoiceAnswers = question.correctAnswer.split(',');
    var studentAnswerSplit = question.studentAnswer.split(',');
    if (this.multipleChoiceAnswers.indexOf(index) > -1) {
      return true;
    }
    else {
      return false;
    }
  }

  checkStudentAnswer(question, index) {
    var studentAnswerSplit = question.studentAnswer.split(',');
    if (studentAnswerSplit.indexOf(index) > -1) {
      return true;
    }
    else {
      return false;
    }

  }

  setBackground() {
    var temp = '';
    console.log(this.studentReport);
    this.studentReport.forEach(obj => {
      if (obj.questionOptions.length > 0 && obj.questionType !== 2 && obj.questionOptions !== undefined) {
        obj.questionOptions.forEach((option, index) => {
          temp = 'answer_options' + '_' + obj.questionId + '_' + index;
          console.log(temp);
          if (obj.studentAnswer !== 'NA') {
            if (obj.correctAnswer == obj.studentAnswer) {
              console.log(document.getElementById('temp'));
              document.getElementById("temp").style.background = '#c1f86f';
            }
            else {
              if (this.getCharacter(index + 97) == obj.correctAnswer) {
                console.log('Correct Answer Marked');
                return document.getElementById("temp").style.background = '#c1f86f';
              }
              if (this.getCharacter(index + 97) == obj.studentAnswer) {
                console.log('Student answer Marked');
                return document.getElementById("temp").style.background = '#ff5f5f';
              }
            }
          }
          else {

          }

        })
      }
    })
  }
  // for decoding choices
  decodeOption(opt) {
    return decodeURIComponent(escape(atob(opt)));
  }

  // for labeling index in alphabets
  getCharacter(index) {
    return String.fromCharCode(index);
  }
}