import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { ApiService } from '../api.service'
import * as globals from './../config/globals';

@Component({
    selector: 'sso-login',
    templateUrl: './sso-login.html',
    styleUrls: ['./sso-login.scss']
})

export class SSOLoginComponent implements OnInit{

    isLoading : boolean = false;
    apiError: boolean = false;
    loginToken: any;
    loginResponse: any;
    globals: any = globals.globals;

    constructor( private router : Router, private route: ActivatedRoute, private apiSrvc: ApiService){
       
    }
    ngOnInit(){
        this.route.params.subscribe(data =>{
            this.loginToken = data.token;
        })
        sessionStorage.clear();
        sessionStorage.setItem('via_sso', '1');
        this.alternateLoginCall(this.loginToken);
    }

    alternateLoginCall(token){
        
        this.isLoading = true;
        var payload = {
            'token': this.loginToken
        }
        this.apiSrvc.alternateLoginCall(payload).subscribe((response: any)=>{
            console.log('login respnse:',response);
            this.loginResponse = response.result;
             this.isLoading = false;
            if(response.statusCode == 200){
                sessionStorage.setItem('sso_login_details', JSON.stringify(this.loginResponse));
                sessionStorage.setItem('login_token', JSON.stringify(payload));
                if(this.loginResponse.hasOwnProperty("component_details")) {
                    sessionStorage.setItem('component_details', this.loginResponse.component_details);
                }
                var authKey: any = btoa(this.loginResponse.institute_id + ':' + this.loginResponse.test_id +':' + this.loginResponse.user_id + ':'+ this.loginResponse.password);
                this.router.navigate(['/', authKey]);
            }  
            else {
                console.log('inside else');
                this.router.navigate(['/notFound'], { skipLocationChange: true });
            }         
               
        },err=>{
            this.isLoading = false;
            this.apiError = err;
            this.router.navigate(['/notFound'], { skipLocationChange: true });
        });
    }
}