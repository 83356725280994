import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-error-template',
  templateUrl: './error-template.component.html',
  styleUrls: ['./error-template.component.scss']
})
export class ErrorTemplateComponent implements OnInit {

  @Input()
  apiError: any;

  constructor() { }

  ngOnInit() {
  }

}
