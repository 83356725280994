import { Component, OnInit, Input } from '@angular/core';
import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';

@Component({
  selector: 'subject-wise-report',
  templateUrl: './subject-wise-report.html',
  styleUrls: ['./subject-wise-report.scss']
})

export class SubjectWiseReportComponent implements OnInit {
  subject_wise_report: any;
  is_neet_test: any;
  subjectData: any = [];
  newArray :any;
  @Input() subjectBasedData ; 
  @Input() isNeetTest ;  
  constructor() {

  }
  ngOnInit() {
    console.log('Inside load');
    this.subject_wise_report = this.subjectBasedData;
    this.is_neet_test = this.isNeetTest;
    console.log('New arary is:',this.subject_wise_report);
   // this.subject_wise_report = JSON.parse(sessionStorage.getItem('subject-wise-report'));
    this.showSubjectWiseReport();
  }

  showSubjectWiseReport() {
    this.subject_wise_report = this.subjectBasedData; 
    this.is_neet_test = this.isNeetTest;
    console.log('Subject wise in sub component', this.subject_wise_report);
    this.subjectData = [];
    this.subject_wise_report.forEach(subj => {
      var obj = {
        left_ques_count: subj.left_ques_count,
        maximum_marks: subj.maximum_marks,
        not_attempt_ques_marks: subj.not_attempt_ques_marks,
        right_ques_count: subj.right_ques_count,
        right_ques_marks: subj.right_ques_marks,
        subject_id: subj.subject_id,
        subject_name: subj.subject_name,
        total_attempted_time_mins: Math.floor(subj.total_attempted_time / 60000) + ' m',
        total_attempted_time_secs : ((subj.total_attempted_time % 60000) / 1000).toFixed(0) + ' s',
        total_score: subj.total_score,
        wrong_ques_count: subj.wrong_ques_count,
        wrong_ques_marks: subj.wrong_ques_marks
      }
      this.subjectData.push(obj);
    })
  }
}

