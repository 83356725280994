import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { parse } from 'path';
import LZString from '../../lz-string.js'

@Component({
    selector: 'guest-subject-wise-report',
    templateUrl: './guest-subject-report.component.html',
    styleUrls: ['./guest-subject-report.scss']
})
export class GuestSubjectWiseReportComponent implements OnInit {
    subjectArray = [];
    questionsArray = [];
    subjectReport = [];
    studentDetails = [];
    totalMarks: any = 0;
    answerPool: any = 0;
    testStatus: any;
    test_data: any;
    studentReport: any = [];
    studentAnswerArray: any = []
    @Output() sendData: EventEmitter<any> = new EventEmitter<any>();
    ngOnInit() {

      //  this.getSubj()
        this.groupQuestionsToSubject();
        this.sendData.emit(this.subjectArray);
    }

    getSubj(){

    }

    groupQuestionsToSubject() {
        this.subjectArray = JSON.parse(sessionStorage.getItem('subjects'));  
        this.testStatus = JSON.parse(sessionStorage.getItem('test_status'));
        console.log('test status:',this.testStatus)   
        let saved = JSON.parse(sessionStorage.getItem('is_data_compress'));
        if(saved == 1) {
          this.questionsArray = JSON.parse(LZString.decompress(sessionStorage.getItem('ques_data')));
        } else {
          this.questionsArray = JSON.parse(sessionStorage.getItem('ques_data'));
        } 
        /* this.questionsArray = JSON.parse(sessionStorage.getItem('ques_data')); */
        this.test_data = JSON.parse(sessionStorage.getItem('test_data'));
        this.studentAnswerArray = JSON.parse(sessionStorage.getItem('user-answers'));
        this.studentReport = [];
        console.log('original ques array:',this.questionsArray);
        this.questionsArray.forEach(element => {
        let obj = {
        questionId: element.ques_id,
        question: this.test_data.is_multi_lingual != 1 ? element.question : '',
        answer: element.answer,
        correctAnswer:element.correct_answer,
        isCorrect : false, 
        questionOptions: this.test_data.is_multi_lingual != 1 ? element.options : '',
        questionFeedback: element.ques_feedback !== '' ? this.decodeOption(element.ques_feedback) : '',
        questionType: element.ques_type_id,
        positiveMarks: element.positive_marks,
        negativeMarks: element.negative_marks,
        notAttemptedMarks: element.not_attempt_marks,
        subjectId: element.subject_id,
        attemptedTime: '',
      };
      this.studentReport.push(obj);
      MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
      MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    });
     this.mapCorrectAnswer();
     this.studentAnswerArray.forEach(obj => {
        var temp ='';
        temp = this.studentReport.find(ob=> ob.questionId == obj.ques_id);
        
      }) 
    this.checkIfAnswerIsCorrect(this.studentReport);
    console.log(this.studentReport);
        this.subjectReport = [];
        var quesArr = JSON.parse(sessionStorage.getItem('questions-list')).split('#');
        var timeArr = JSON.parse(sessionStorage.getItem('attempted-time')).split('#');
        this.subjectArray.forEach(obj => {
            obj.questionArray = [];
        });
        this.studentReport.forEach((k, v) => {
            if (this.subjectArray.find(ob => ob.subject_id == k.subjectId) !== undefined) {
                this.subjectArray.find(ob => ob.subject_id == k.subjectId).questionArray.push(k);
            }
        });
            this.subjectArray.forEach((key,index)=>{
                key.questionArray.forEach((ob,val)=>{
                    if(quesArr.indexOf((ob.questionId).toString()) > -1){
                        var temp = quesArr.indexOf((ob.questionId).toString());
                       key.questionArray[val].attemptedTime = timeArr[temp];
                    }
                    if( ob.studentAnswer == -1 || ob.studentAnswer != ob.correctAnswer ){
                        ob.isCorrect = false;
                    }
                    else if(ob.studentAnswer == ob.correctAnswer){
                        ob.isCorrect = true;
                    }
                });
        }); 
        console.log('subject array',this.subjectArray); 
        this.calcTotalMarksOfSubject();
    }

        calcTotalMarksOfSubject() {
        this.subjectArray.forEach((item,val) =>{
            item.sum = 0;
            item.correctAnswer = 0;
            item.incorrectAnswer = 0;
            item.positiveMarks = 0;
            item.negativeMarks = 0;
            item.unattemptedmarks = 0;    
            item.totalMarks = 0;     
            item.totalTime = 0;  
            item.questionArray.forEach((el,index)=>{
               if(el.isCorrect == 1){
               item.correctAnswer = item.correctAnswer + 1;
               item.positiveMarks = item.positiveMarks + parseFloat(el.positiveMarks);
               item.sum = item.sum + parseFloat(el.positiveMarks);
               item.totalMarks = item.totalMarks + parseFloat(el.positiveMarks);
               item.totalTime = item.totalTime + parseInt(el.attemptedTime);
               }
               else if(el.isCorrect == 0 && el.studentAnswer != -1){
               item.incorrectAnswer = item.incorrectAnswer + 1;
               item.negativeMarks = item.negativeMarks - parseFloat(el.negativeMarks) - parseFloat(el.notAttemptedMarks);
               item.sum = item.sum - parseFloat(el.negativeMarks);
               item.totalTime = item.totalTime + parseInt(el.attemptedTime);
               }
               else if(el.studentAnswer == -1){
                item.unattemptedmarks = item.unattemptedmarks - parseFloat(el.notAttemptedMarks);
                item.sum = item.sum - parseFloat(el.notAttemptedMarks);
                item.totalTime = item.totalTime + parseInt(el.attemptedTime);
               }
           })
        })

        
        let is_neet_test_flag = (sessionStorage.getItem('is_neet_test'));
        if(is_neet_test_flag == "1"){
          let test_subjects = [];
          for (let index = 0; index < this.subjectArray.length; index++) {
            const element = this.subjectArray[index];
            let qindex = test_subjects.findIndex(data => data.subject_id == element.subject_id);
            if(qindex == -1){
              test_subjects.push(element)
            }
          }
          this.subjectArray = test_subjects;
        }
       
    }

       checkIfAnswerIsCorrect(studentData){
        var getSubjectiveQuestions = studentData.filter(ob => ob.questionType == 2);
        getSubjectiveQuestions.forEach((k,v)=>{
          k.studentAnswer = this.testStatus.subjective_answer[v] != '-1' ? decodeURIComponent(escape(window.atob(this.testStatus.subjective_answer[v]))) : 'NA';
        })
        
        studentData.forEach((key,val)=>{
       
        if(key.studentAnswer == -1 || key.studentAnswer == 'NA' || key.correctAnswer != key.studentAnswer){
          key.isCorrect = false;
        }
        else {
          key.isCorrect =  true;
        }
      })
        
      }
    

    mapCorrectAnswer() {
        this.studentAnswerArray.forEach((key, value) => {
          this.studentReport[value].studentAnswer = '';
          this.studentReport[value].studentAnswer = this.studentAnswerArray[value];
        })
        console.log('Array after addimg elmts:', this.studentReport);
       // sessionStorage.setItem('student-answer-report', JSON.stringify(this.studentReport));
      }

    decodeOption(opt) {
        return decodeURIComponent(escape(atob(opt)));
      }
    

    getTotalMarksPersubject(ques){
      console.log('inside total marks calc:',ques)
        var sum = 0;
        ques.forEach(obj=>{
          sum = sum + parseInt(obj.positiveMarks);
        });
        return sum;
    }

    calcTimeinSecs(time){
       /*  var sum = 0;
        var time_taken: any;
        ques.forEach(obj=>{
            sum = sum + parseFloat(obj.attemptedTime);
        }); */

        /* time_taken = Math.floor(sum / 60000) + ' m ' +((sum % 60000) / 1000).toFixed(0) + ' s';
        return time_taken; */
        return (Math.floor(time / 60000) + ' m ' + ((time % 60000 )/1000).toFixed(0) + ' s ');
    }

}