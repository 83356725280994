import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Exam Panel';

  constructor(private deviceService: DeviceDetectorService)
  {
    if(localStorage.getItem('identityKey') == undefined)
    {
      localStorage.setItem('identityKey', Math.random().toString(36).slice(2));
    }
    let deviceInfo = this.deviceService.getDeviceInfo();
    localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
  }
}
