import { HostListener, Component, OnInit, Renderer, ViewChild, EventEmitter, Output } from '@angular/core';


@Component({
    selector: 'link-not-found',
    templateUrl: './not-found.html',
  })
  
  export class NotFoundLink implements OnInit {

    ngOnInit(){
        
    }

  }