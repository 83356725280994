import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-test-instructions',
  templateUrl: './test-instructions.component.html',
  styleUrls: ['./test-instructions.component.scss']
})
export class TestInstructionsComponent implements OnInit {

  @Input()
  language: any;

  test_instructions: any;
  test_instructions_eng: any;
  test_instructions_hindi: any;
  test_instructions_kannada: any;
  test_data: any;
  constructor() { }

  ngOnInit() {
    if (sessionStorage.getItem('test_data') == undefined) {
      //this.router.navigate(['/']);
    }
    else {
      this.test_data = JSON.parse(sessionStorage.getItem('test_data'));
      console.log(this.test_data);
      // this.test_instructions = atob(this.test_data.instruction_description);
      this.test_instructions = decodeURIComponent(escape(window.atob(this.test_data.instruction_description)))
      this.test_instructions_eng = this.test_instructions;
      this.test_instructions_hindi = this.test_instructions;
      this.test_instructions_kannada = this.test_instructions;
      if (this.test_data.instruction_content != null) {
        let instruction_content = this.test_data.instruction_content;
        console.log(this.language);
        instruction_content.forEach(element => {
          if (element.language_id == 1) {
            this.test_instructions_eng = decodeURIComponent(escape(window.atob(element.description)));
          } else if (element.language_id == 2) {
            this.test_instructions_hindi = decodeURIComponent(escape(window.atob(element.description)));
          } else if (element.language_id == 3) {
            this.test_instructions_kannada = decodeURIComponent(escape(window.atob(element.description)));
          }
        });
      }
    }
  }

}
