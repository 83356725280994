import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { PanelComponent } from './panel/panel.component';
import { ResultComponent } from './result/result.component';
import { GuestScoreComponent } from './guest-score/guest-score.component';
import { AdvancedResultComponent } from './advanced-result/advanced-result.component';
import { NoQuestionPanelLayout } from './no-questions/no-questions';
import { SSOLoginComponent } from './sso-login/sso-login.component';
import { NotFoundLink } from './not-found-link/not-found.component';

const routes: Routes = [
  { path: '', component: WelcomeComponent},
  { path: 'sso/:token', component: SSOLoginComponent},
  { path: 'instructions', component: InstructionsComponent},
  { path: 'noQues',component: NoQuestionPanelLayout},
  { path: 'notFound', component: NotFoundLink},
  { path: 'panel', component: PanelComponent},
  { path: 'result', component: ResultComponent},
  { path: 'score', component: GuestScoreComponent},
  { path: 'advresult/:resultKey', component: AdvancedResultComponent},
  { path: ':token', component: HomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
