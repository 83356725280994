import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hindi',
  templateUrl: './hindi.component.html',
  styleUrls: ['./hindi.component.scss']
})
export class HindiComponent implements OnInit {

  @Input()
  test_instructions: any;
  
  constructor() { }

  ngOnInit() {
  }

}
