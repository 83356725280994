import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Common } from './services/common.service';
import { Exam } from './services/exam.service';
import { Chart } from 'chart.js';
import { NgxGaugeModule } from 'ngx-gauge';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { PanelComponent } from './panel/panel.component';
import { PanelLayout1Component } from './panel/templates/panel-layout1/panel-layout1.component';
import { PanelLayout2Component } from './panel/templates/panel-layout2/panel-layout2.component';
import { PanelLayout3Component } from './panel/templates/panel-layout3/panel-layout3.component';
import { PanelDefaultComponent } from './panel/templates/panel-default/panel-default.component';
import { TestInstructionsComponent } from './core/test-instructions/test-instructions.component';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './api.service';
import { LoaderComponent } from './core/loader/loader.component';
import { MomentModule } from 'ngx-moment';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { OptionsFormatComponent } from './panel/templates/core/options-format/options-format.component';
import { EnglishComponent } from './core/test-instructions/english/english.component';
import { HindiComponent } from './core/test-instructions/hindi/hindi.component';
import { KannadaComponent } from './core/test-instructions/kannada/kannada.component';
import { ErrorTemplateComponent } from './core/error-template/error-template.component';
import { ResultComponent } from './result/result.component';
import { MediaComponent } from './panel/templates/core/media/media.component';
import { GuestScoreComponent } from './guest-score/guest-score.component';
import { SafeUrlDirective } from './core/safe-url.directive';
import { QuestionWiseReportComponent } from './result/question-wise-report/question-wise-report.component';
import { SubjectWiseReportComponent } from './result/subject-wise-report/subject-wise-report.component';
import { GuestQuestionWiseReportComponent} from './guest-score/guestQuestionReport/guest-question-wise-report.component';
import { GuestSubjectWiseReportComponent} from './guest-score/guestSubjectReport/guest-subject-report.component';
import { PanelCalculatorComponent } from './panel/templates/panel-layout1/panel-calculator/panel-calculator.component';
import { AdvancedResultComponent } from './advanced-result/advanced-result.component';
import { NoQuestionPanelLayout } from './no-questions/no-questions';
import { SSOLoginComponent } from '../app/sso-login/sso-login.component';
import { NotFoundLink } from '../app/not-found-link/not-found.component';
import { safeHtmlPipe } from './safe-html.pipe';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WelcomeComponent,
    InstructionsComponent,
    PanelComponent,
    PanelLayout1Component,
    PanelLayout2Component,
    PanelLayout3Component,
    PanelDefaultComponent,
    TestInstructionsComponent,
    LoaderComponent,
    OptionsFormatComponent,
    EnglishComponent,
    HindiComponent,
    KannadaComponent,
    ErrorTemplateComponent,
    ResultComponent,
    MediaComponent,
    GuestScoreComponent,
    SafeUrlDirective,
    QuestionWiseReportComponent,
    SubjectWiseReportComponent,
    GuestQuestionWiseReportComponent,
    GuestSubjectWiseReportComponent,
    PanelCalculatorComponent,
    AdvancedResultComponent,
    NoQuestionPanelLayout,
    SSOLoginComponent,
    NotFoundLink,
    safeHtmlPipe
    

  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule, 
    AppRoutingModule,
    NgbModule,
    MomentModule,
    NgxGaugeModule,
    DeviceDetectorModule.forRoot(),
    
  ],
  providers: [ApiService, CookieService, Common, Exam, NgbActiveModal],
  bootstrap: [AppComponent]
})
export class AppModule { }
