import { Injectable } from '@angular/core';
import { Title, DomSanitizer }     from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class Common {

    username: string = 'Guest User';
    test_name: string = 'Sample Test';

    loading: boolean = true;
    error: boolean = false;

    authorised: boolean = false;
    institute_data: any;
    test_data: any;
    student_data: any;
    test_type: string;

    constructor(
        private sanitizer:DomSanitizer, 
        private modalService: NgbModal, 
        private titleService: Title
    ) 
    { }

    sanitize(url:string){
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    setTitle(title: string) {
        this.titleService.setTitle( title );
    }

    switch_active = function(renderer, childs, className, activeTarget)
    {
        for (let index = 0; index < childs.length; index++) {
        const element = childs[index];
        renderer.setElementClass(element, className, false);
        }
        renderer.setElementClass(activeTarget, className ,true);
    }

    openModal = function (instructions) {
        this.modalService.open(instructions, { centered: true, size: 'lg' });
    }

    openVerticallyCentered(modal) {
        this.modalService.open(modal, { centered: true });
      }
}