import { HostListener, Component, OnInit, Renderer, ViewChild, EventEmitter, Output } from '@angular/core';


@Component({
    selector: 'no-questions-panel',
    templateUrl: './no-questions.component.html',
    styleUrls: ['./no-questions.scss']
  })
  
  export class NoQuestionPanelLayout implements OnInit {

    ngOnInit(){
        
    }

  }
  